<template>
  <Breadcrumbs title="House List" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex gap-3">
            <div class="select-wrap">
              <multiselect v-model="listRequest.country" :options="options.country" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="Country" @update:modelValue="val => selectChange(val, 'country')">
              </multiselect>
            </div>
            <div class="select-wrap">
              <multiselect v-model="listRequest.state" :options="options.state" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="State" @update:modelValue="val => selectChange(val, 'state')">
              </multiselect>
            </div>
            <div class="select-wrap">
              <multiselect v-model="listRequest.city" :options="options.city" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="City">
              </multiselect>
            </div>
            <div class="select-wrap">
              <multiselect v-model="listRequest.assigned" :options="options.assigned" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="Assigned">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="getList">Search</button>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-center search-box">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" @click="showModal('addHouse')" class="btn btn-primary">+ Add House</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">House Id</th>
                  <th scope="col">House</th>
                  <th scope="col">Owner</th>
                  <th scope="col" @click="sortList('gateway')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Gateway</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('gateway') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('gateway') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('sensor')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Sensor</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('sensor') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('sensor') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Assigned</th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">QR Code</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in houseList" :key="item.id">
                  <td>#{{ item.hid }}</td>
                  <td>
                    <div class="d-flex align-items-center gap-2">
                      <img class="img-50" :src="item.house.image || 'https://fakeimg.pl/50x50/?text=50x50'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.house.name }}</p>
                        <p class="mb-0">{{ item.house.address }}</p>
                      </div>
                    </div>
                  </td>
                  <td v-if="item.owner">
                    <div class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.owner.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.owner.first_name }} {{ item.owner.last_name }}</p>
                        <p class="mb-0">{{ item.owner.code }} {{ item.owner.phone }}</p>
                      </div>
                    </div>
                  </td>
                  <td v-else>None</td>
                  <td>{{ item.gateway }}</td>
                  <td>{{ item.sensor }}</td>
                  <td v-if="item.assigned">
                    <div class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.assigned.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.assigned.first_name }} {{ item.assigned.last_name }}</p>
                        <p class="mb-0">{{ item.assigned.email }}</p>
                      </div>
                    </div>
                  </td>
                  <td v-else>None</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <a href="javascript:;" @click.prevent="getQRcode(item.id)">Download</a>
                  </td>
                  <td>
                    <router-link :to="{ name: 'houseDeviceList', params: { houseId: item.id } }">Details</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addHouse v-if="shown.addHouse" ref="addHouse" @hide="hideModal('addHouse')" @getList="getList" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addHouse from '@/components-gc/modal/houseList/addHouse.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      modals: {},
      houseList: [],
      listRequest: {
        country: "",
        state: "",
        city: "",
        search: "",
        assigned: "",
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
      },
      total: 0,
      options: {
        country: [],
        state: [],
        city: [],
        assigned: ['assigned', 'unassigned']
      },
      selected: {
        assigned: ''
      },
      shown: {
        addHouse: false,
      },
      qrcodeImg: '',
      verifyCode: ''
    };
  },
  components: {
    addHouse,
    Pagination
  },
  mounted() {
    this.getCountry();
    this.getList();
  },
  watch: {
     'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.getList();
      }
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'house' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'house', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'house', country: this.listRequest.country, state: this.listRequest.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getList() {
      const api = `${process.env.VUE_APP_PATH}/house/list`;
      this.axios.post(api, this.listRequest)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.houseList = res.data.data;
          this.total = res.data.total;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = '';
        this.listRequest.city = '';
        this.options.state = [];
        this.options.city = [];
        if(val) {
          this.getState();
        }
      } else if(field === 'state') {
        this.listRequest.city = ''; 
        this.options.city = [];
        if(val) {
          this.getCity();
        }
      }
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
     downloadQR() {
      if (this.qrcodeImg && this.verifyCode) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = `data:image/png;base64,${this.qrcodeImg}`;
        
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height + 50; // 增加高度以顯示 verifyCode
          
          ctx.drawImage(img, 0, 0);
          ctx.font = '20px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(this.verifyCode, canvas.width / 2, img.height + 30); // 在 QR Code 下方顯示 verifyCode
          
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = 'qrcode_with_verify_code.png';
          document.body.appendChild(link); // 將 <a> 元素添加到 DOM 中
          link.click(); // 觸發點擊事件
          document.body.removeChild(link); // 點擊後移除 <a> 元素
            this.qrcodeImg = '';
          this.verifyCode = '';
        };
      } else {
        console.error('QR Code can not be downloaded');
      }
    },
    getQRcode(id) {
      const api = `${process.env.VUE_APP_PATH}/house/download_qrcode`;
      this.axios.post(api, {
        house_id : id
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.qrcodeImg = res.data.image;
          this.verifyCode = res.data.verify_code;
          this.downloadQR(id);
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  }
};
</script>