import { createWebHistory, createRouter } from "vue-router";
import store from '../store/index'; 
import Body from '../components/body';
import Dashboard from '../pages-gc/dashboard';
import login from '../auth/login';
import Error404 from '../pages/error/error404';
// User Management
import UserManagementIndex from '../pages-gc/userManagement/index';
import UserDetailLayout from '../pages-gc/userManagement/detailLayout';
import UserViewDetail from '../pages-gc/userManagement/viewDetail';
import UserHouseDetailLayout from '../pages-gc/userManagement/houseDetail/layout.vue';
// Staff Management
import StaffManagementIndex from '../pages-gc/staffManagement/index';
import StaffDetailLayout from '../pages-gc/staffManagement/detailLayout';
import StaffViewDetail from '../pages-gc/staffManagement/viewDetail';
import StaffCaseLayout from '../pages-gc/staffManagement/caseLayout.vue';
import StaffCaseDetail from '../pages-gc/staffManagement/caseDetail.vue';
import StaffHouseDetailLayout from '../pages-gc/staffManagement/reportDetail/layout.vue';
// House List
import HouseListIndex from '../pages-gc/houseList/index';
import HouseDetailLayout from '../pages-gc/houseList/houseDetail/layout.vue';

// Device
import DeviceGateway from '../pages-gc/device/gateway.vue';
import DeviceSensor from '../pages-gc/device/sensor.vue';
import DeviceList from '../pages-gc/device/deviceList.vue';
import SensorLayout from '../pages-gc/device/sensorLayout.vue';
import MoistureLevel from '../pages-gc/device/moistureLevel.vue';
import Temperature from '../pages-gc/device/temperature.vue';
import Humidity from '../pages-gc/device/humidity.vue';
import Files from '../pages-gc/device/files.vue';

// Notifications
import NotificationsIndex from '../pages-gc/notifications/index';
import NotificationsDetail from '../pages-gc/notifications/detail';

// Device Management
import DeviceManagement from '../pages-gc/deviceManagement.vue';

// Setting
import TermsOfService from '../pages-gc/setting/termsOfService.vue';
import TermsDetail from '../pages-gc/setting/termsDetail.vue'; 
import MemberCriteria from '../pages-gc/setting/memberCriteria.vue';
import MemberDetail from '../pages-gc/setting/memberDetail.vue';
import ContactToService from '../pages-gc/setting/contactToService.vue';
import ContactToServiceDetail from '../pages-gc/setting/contactToServiceDetail.vue';
import Information from '../pages-gc/setting/information.vue';
import InformationDetail from '../pages-gc/setting/informationDetail.vue';
import AboutUs from '../pages-gc/setting/aboutUs.vue';
import AboutUsDetail from '../pages-gc/setting/aboutUsDetail.vue';
import Faq from '../pages-gc/setting/faq.vue';
import FaqDetail from '../pages-gc/setting/faqDetail.vue';
import Administrator from '../pages-gc/setting/administrator.vue';
import AdministratorDetail from '../pages-gc/setting/administratorDetail.vue';
import RoleSetting from '../pages-gc/setting/roleSetting.vue';
import RoleSettingDetail from '../pages-gc/setting/roleSettingDetail.vue';
import Update from '../pages-gc/setting/update.vue';
import UpdateDetail from '../pages-gc/setting/updateDetail.vue';

import DeleteAccountRequest from '../pages-gc/deleteAccountRequest.vue';
import NodeLogList from '../pages-gc/nodeLogList.vue';
import DeleteAccount from '../pages-gc/deleteAccount.vue';


// template
/* Dashboards */
// import Default from '../pages/dashboard/default';
// import Ecommerce from '../pages/dashboard/ecommerce';
// import Online from  '../pages/dashboard/online'
// import Crypto from '../pages/dashboard/crypto'
// import Social from '../pages/dashboard/social.vue'
// // Widgets
// import General from '../pages/widgets/general'
// import Chart from '../pages/widgets/chart'
// // pageLayout
// import indexBoxed from '../pages/pageLayout/boxed/indexBoxed.vue'
// import indexRTL from '../pages/pageLayout/RTL/indexRTL.vue'
// import indexDarklayout from '../pages/pageLayout/darklayout/indexDarklayout.vue'
// import indexFooterdark from '../pages/pageLayout/footerdark/indexFooterdark.vue'
// import indexFooterfixed from '../pages/pageLayout/footerfixed/indexFooterfixed.vue'
// import indexFooterlight from '../pages/pageLayout/footerlight/indexFooterlight.vue'
// import indexHidenavscroll from '../pages/pageLayout/hidenavscroll/indexHidenavscroll.vue'
// // project
// import createProject from '../pages/project/createproject/createProject.vue'
// import indexList from '../pages/project/projectlist/indexList.vue'
// /* File Manager  */
// import FileManager from '../pages/filemanager';
// // kanban
// import Kanban from '../pages/kanban/indexKanban.vue'
// // ecoomerce
// import indexProduct from '../pages/ecommerce/product/indexProduct.vue'
// import productPage from '../pages/ecommerce/productpage/productPage.vue'
// import productList from '../pages/ecommerce/list/productList.vue'
// import paymentDetails from '../pages/ecommerce/payment/details/paymentDetails.vue'
// import orderHistory from '../pages/ecommerce/order/orderHistory.vue'
// import invoiceView from '../pages/ecommerce/invoice/invoiceView.vue'
// import cartView from '../pages/ecommerce/cart/cartView.vue'
// import wishlistView from '../pages/ecommerce/wishlist/wishlistView.vue'
// import checkoutView from '../pages/ecommerce/checkout/checkoutView.vue'
// import pricingView from '../pages/ecommerce/pricing/pricingView.vue'
// // mail
// import readMail from '../pages/email/readMail/readMail.vue'
// import composeView from '../pages/email/compose/composeView.vue'
// // chat
// import chatApp from '../pages/chat/chatApp/chatApp.vue'
// import videoChat from '../pages/chat/videoChat/videoChat.vue'
// // user
// import userProfile from '../pages/users/profile/userProfile.vue'
// import userCards from '../pages/users/cards/userCards.vue'
// import userEdit from '../pages/users/edit/userEdit.vue'
// // forms
// import formValidation from "../pages/forms/formValidetion/formValidation"
// import base_Input from "../pages/forms/baseInput/base_Input"
// import checkbox_radio from "../pages/forms/Checkbox&Radio/checkbox_radio"
// import input_groups from "../pages/forms/InputGroup/input_groups"
// import megaOptions from "../pages/forms/megaOptions/megaOptions"

// // form widgets
// import select2 from "../pages/formWidgets/select2/select2Page.vue"
// import switch_From from "../pages/formWidgets/switch/switch_From"
// import touchspin_Form from "../pages/formWidgets/touchspin/touchspin_Form"
// import typeahead_Form from "../pages/formWidgets/typeahead/typeahead_Form"
// import clipboard_Form from "../pages/formWidgets/clipboard/clipboard_Form"
// import datepicker from "../pages/formWidgets/datepicker/datePicker.vue"

// // form layput
// import defaultforms from '../pages/formLayout/default/defaultFotrm.vue'
// import form_wizard2 from "../pages/formLayout/formWizrd2/form_wizard.vue"
// import form_wizard1 from '../pages/formLayout/formWizard1/form_wizard2.vue'
// import form_wizard3 from "../pages/formLayout/formWizard3/form_wizard1.vue"

// // uikits
// import Scrollable from "../pages/advance/scollable/scrollable_advance.vue";
// import Sweetalert from "../pages/advance/sweetalert/sweetAert.vue";
// import Tree from "../pages/advance/tree/treeView.vue";
// import Rating from "../pages/advance/rating/ratingView.vue";
// import Pagination from "../pages/advance/pagination/pagination_advance.vue";
// import Ribbons from "../pages/advance/ribbons/ribbons_advance.vue";
// import Tour from "../pages/advance/tour/tourView.vue";

// import Breadcrumb from "../pages/advance/breadcrumbs/breadCrumb.vue";

// import Sticky from "../pages/advance/sticky/stickyView.vue";

// import ImageCropper from "../pages/advance/image_cropper/cropper_advance.vue";
// import Notify from '../pages/advance/notify/toaster_advance.vue'
// import dropzone from '../pages/advance/dropzone/dropzoneView.vue';
// import animatedModal from '../pages/advance/animatedModal/animatedModal.vue'
// import owlCarousel from '../pages/advance/owlCarousel/owlCarousel.vue' 
// import Rangeslider from '../pages/advance/range/rangeView.vue'
// import BasicCard from '../pages/advance/basicCard/basicCard.vue'
// import CreativeCard from '../pages/advance/creativeCard/creative_card.vue'
// import DraggableCard from '../pages/advance/draggableCard/draggableCard.vue'
// import TabbedCard from '../pages/advance/tabbedCard/tabbed_card.vue'
// import timeLine from '../pages/advance/timeLine/timeLine.vue'
// /* FAQ */
// import Faq from '../pages/faq/faqIndex.vue';
// // uikits
// import alert from '../pages/uikits/alert';
// import Helperclasses from '../pages/uikits/helper_classes';
// import Avatars from '../pages/uikits/avatars';
// import Grid from '../pages/uikits/grid';
// import Tagpills from '../pages/uikits/tag_pills';
// import Typography from '../pages/uikits/typography';
// import Progressbar from '../pages/uikits/progress_bar';
// import Modal from '../pages/uikits/modal';
// import Popover from '../pages/uikits/popover';
// import Tooltip from '../pages/uikits/tooltip';
// import Spinners from '../pages/uikits/loader';
// import Dropdown from '../pages/uikits/dropdown';
// import Accordion from '../pages/uikits/accordion';
// import Boxshadow from '../pages/uikits/box_shadow';
// import Lists from '../pages/uikits/lists';
// import bootstraptabPage from "../pages/uikits/tabs/bootstraptab/bootstraptabPage.vue";
// import linetabPage from "../pages/uikits/tabs/linetab/linetabPage.vue";
// // animation
// import animate from '../pages/animation/animate/animatePage.vue'
// import aos from '../pages/animation/aos/aosPage.vue'
// import scroll from '../pages/animation/scroll/scrollPage.vue'
// import wow from '../pages/animation/wow/wowPage.vue'
// import tilt from '../pages/animation/tilt.vue'
// // builder
// import form1 from '../pages/builder/formBuilder1.vue'
// import form2 from '../pages/builder/formBuilder2.vue'
// /* icons */
// import Flag from '../pages/icons/flag';
// import Ico from '../pages/icons/ico_icon';
// import Themify from '../pages/icons/themify_icon';
// import Fontawesome from '../pages/icons/font_awesome';
// import FeatherIcon from '../pages/icons/feather_icon';
// import WhetherIcon from '../pages/icons/weather';
// /* Buttons */
// import Default_buttons from '../pages/button/Default/default_button.vue';
// import Flat_buttons from '../pages/button/Flat/flat_button.vue';
// import Edge_buttons from '../pages/button/Edge/edge_button.vue';
// import Raised_buttons from '../pages/button/Raised/raised_button.vue';
// import Group_buttons from '../pages/button/ButtonGroup/button_group.vue'; 
// /*charts*/ 
// import google_chart from "../pages/charts/googleChart/google_chart"
// import apex_chart from "../pages/charts/ApexChart/apex_chart"
//  import chartist_chart from "../pages/charts/chartist/chartist_chart.vue"
// // knowledge
// import knowledge from '../pages/Knowledgebase/index.vue'
// import category_knowledge from '../pages/Knowledgebase/categoryKnowledge.vue'
// import detail_knowledge from '../pages/Knowledgebase/detailsKnowledge.vue' 
// // error
// import Error400 from '../pages/error/error400';
// import Error401 from '../pages/error/error401';
// import Error403 from '../pages/error/error403';
// import Error404 from '../pages/error/error404';
// import Error500 from '../pages/error/error500';
// import Error503 from '../pages/error/error503';

// /* Support Ticket */
// import Support from '../pages/support/support.vue';

// /* Editor */
// import SimpleEditor from '../pages/editor/simple_editor';
// import CkEditor from '../pages/editor/ckeditor.vue';
// /* Maps */
// import GoogleMaps from '../pages/maps/google_map.vue';
// import VueLeaflet from '../pages/maps/vue_leaflet.vue';
// // learning
// import learning_list from "../pages/learning/learninglist/learning_list"
// import coursedetailed from "../pages/learning/courseDetailed"
// // job Search
// import job_list from "../pages/job/job-list/job_list"
// import job_details from "../pages/job/job_details/job_details"
// import job_apply from "../pages/job/job_apply/job_apply"
// import job_card from "../pages/job/job_card/cardJob.vue"
// // blog
// import blog_detail from "../pages/blog/blog-details/blog_detail"
// import blog_single from "../pages/blog/blog-single/blog_single"
// import blog_add from "../pages/blog/blog-add/addBlog.vue"
// // GALLERY
// import grid_gallery from "../pages/gallery/grid_gallery"
// import grid_desc from "../pages/gallery/grid_desc"
// import hover_gallery from "../pages/gallery/hover-gallery/hover_gallery"
// import masonry_gallery from "../pages/gallery/masonry-gallery/masonry_gallery"
// import masonary_desc from "../pages/gallery/masonary_desc.vue"
// /* Authentication */
// import LoginOne from '../pages/authentication/login_one';
// import LoginTwo from '../pages/authentication/login_two';
// import LoginValidate from '../pages/authentication/login_validation.vue';
// import LoginTooltip from '../pages/authentication/login_tooltip.vue'
// import LoginSweetalert from '../pages/authentication/login_sweetalert.vue'

// import RegisterImage from '../pages/authentication/register_image';
// import RegisterImage2 from '../pages/authentication/register_image2';
// import Unlock from '../pages/authentication/unlock';
// import ForgetPassword from '../pages/authentication/forget_password';
// import ResetPassword from '../pages/authentication/reset_password';
// import Maintenance from '../pages/authentication/maintenance.vue'
// /* Auth */
// import login from '../auth/login';
// import Register from '../auth/register';
// // comingsoon
// import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
// import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';
// import ComingsoonVideo from '../pages/comingsoon/comingsoon_video';
// // SAMPLE PAGE
// import sample_page from "../pages/sample_page"
// /* bootstrap tables */
// import BootstrapBasic from "../pages/tables/bootstrapTable/basicTables/basic_tables.vue";
// import tableComponent from "../pages/tables/bootstrapTable/stylingtable/styling_table.vue"
// import DatatableBasic from "../pages/tables/dataTable/BasicInit/basic_Init.vue"
// // searchPage
// import serchIndex from "../pages/search/serchIndex.vue"
// // bookmark
// import Bookmark from "../pages/bookmark/bookMark.vue"
// // contact 
// import contactView from "../pages/contact/contactView.vue"
// // task
// import Task from "../pages/task/indexPage.vue"
// // calendar
// import Calendar from "../pages/calenderView.vue"
// // social app
// import socialPage from "../pages/socialApp/socialApp.vue"
// // todo
// import Todo from "../pages/todo/index.vue"
// import { children } from "bootstrap/js/dist/dom/selector-engine";

const routes = [
    {
      path: '/',
      component: Body,
      children: [
        {
          path: '',
          name: 'defaultRoot',
          component: Dashboard,
          meta: {
            title: ' Nisus',
          }
        },
  
      ]
    },
    {
        path: '/auth',
        children: [
        {
          path: 'login',
          name: 'Login 1',
          component: login,
          meta: {
            title: ' login | Nisus',
          }
        }
        ]
      },
    {
      path:"/user-management",
      component: Body,
      children:[
        {
          path:"index",
          name:"userManagementIndex",
          component: UserManagementIndex,
          meta: {
            title: ' User Management | Nisus',
          }
        },
        {
          path:"view-detail/:userId",
          name:"userDetailLayout",
          component: UserDetailLayout,
          meta: {
            title: ' View Detail | Nisus',
          },
          children: [
            {
              path:"",
              name:"userManagementViewDetail",
              component: UserViewDetail,
            },
            {
              path:"house-detail/:houseId",
              name:"userHouseDetailLayout",
              component: UserHouseDetailLayout,
              meta: {
                title: ' House Detail | Nisus',
              },
              children: [
                {
                  path: "",
                  name:"userDeviceList",
                  component: DeviceList
                },
                {
                  path: "gateway/:gatewayId",
                  name:"userGateway",
                  component: DeviceGateway
                },
                {
                  path: "sensor/:sensorId",
                  name:"userSensorLayout",
                  component: SensorLayout,
                  children: [
                    {
                      path: "",
                      name:"userSensor",
                      component: DeviceSensor
                    },
                    {
                      path: "moisture-level",
                      name:"userMoistureLevel",
                      component: MoistureLevel
                    },
                    {
                      path: "temperature",
                      name:"userTemperature",
                      component: Temperature
                    },
                    {
                      path: "humidity",
                      name:"userHumidity",
                      component: Humidity
                    },
                    {
                      path: "files",
                      name:"userFiles",
                      component: Files
                    },
                  ]
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path:"/staff-management",
      component: Body,
      children:[
        {
          path:"index",
          name:"staffManagementIndex",
          component: StaffManagementIndex,
          meta: {
            title: ' Staff Management | Nisus',
          }
        },
        {
          path:"view-detail/:staffId",
          name:"staffViewDetail",
          component: StaffViewDetail,
          meta: {
            title: ' View Detail | Nisus',
          },
          children: [
            {
              path:"",
              name:"staffDetailLayout",
              component: StaffDetailLayout,
            },
          ]
        },
        {
          path:"case-detail/:caseId",
          name:"StaffCaseLayout",
          component: StaffCaseLayout,
          meta: {
            title: ' Case Detail | Nisus',
          },
          children: [
            {
              path: "",
              name:"staffCaseDetail",
              component: StaffCaseDetail
            },
            {
              path:"house-detail/:houseId",
              name:"staffHouseDetailLayout",
              component: StaffHouseDetailLayout,
              meta: {
                title: ' House Detail | Nisus',
              },
              children: [
                {
                  path: "",
                  name:"staffDeviceList",
                  component: DeviceList
                },
                {
                  path: "gateway/:gatewayId",
                  name:"staffGateway",
                  component: DeviceGateway
                },
                {
                  path: "sensor/:sensorId",
                  name:"staffSensorLayout",
                  component: SensorLayout,
                  children: [
                    {
                      path: "",
                      name:"staffSensor",
                      component: DeviceSensor
                    },
                    {
                      path: "moisture-level",
                      name:"staffMoistureLevel",
                      component: MoistureLevel
                    },
                    {
                      path: "temperature",
                      name:"staffTemperature",
                      component: Temperature
                    },
                    {
                      path: "humidity",
                      name:"staffHumidity",
                      component: Humidity
                    },
                    {
                      path: "files",
                      name:"staffFiles",
                      component: Files
                    },
                  ]
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path:"/house-list",
      component: Body,
      children:[
        {
          path:"index",
          name:"houseListIndex",
          component: HouseListIndex,
          meta: {
            title: ' House List | Nisus',
          }
        },
        {
          path:"house-detail/:houseId",
          name:"houseDetailLayout",
          component: HouseDetailLayout,
          meta: {
            title: ' House Detail | Nisus',
          },
          children: [
            {
              path: "",
              name:"houseDeviceList",
              component: DeviceList
            },
            {
              path: "gateway/:gatewayId",
              name:"houseGateway",
              component: DeviceGateway
            },
            {
              path: "sensor/:sensorId",
              name:"houseSensorLayout",
              component: SensorLayout,
              children: [
                {
                  path: "",
                  name:"houseSensor",
                  component: DeviceSensor
                },
                {
                  path: "moisture-level",
                  name:"houseMoistureLevel",
                  component: MoistureLevel
                },
                {
                  path: "temperature",
                  name:"houseTemperature",
                  component: Temperature
                },
                {
                  path: "humidity",
                  name:"houseHumidity",
                  component: Humidity
                },
                {
                  path: "files",
                  name:"houseFiles",
                  component: Files
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path:"/notifications",
      component: Body,
      children:[
        {
          path:"index",
          name:"notificationsIndex",
          component: NotificationsIndex,
          meta: {
            title: ' Notifications | Nisus',
          }
        },
        {
          path:"notifications-detail/:notificationsId",
          name:"notificationsDetail",
          component: NotificationsDetail,
          meta: {
            title: ' Notifications Detail | Nisus',
          }
        }
      ]
    },
    {
      path:"/device-management",
      component: Body,
      children:[
        {
          path:"",
          name:"deviceManagement",
          component: DeviceManagement,
          meta: {
            title: ' Device Management | Nisus',
          }
        },
      ]
    },
    {
      path:"/setting",
      component: Body,
      children:[
        {
          path:"terms-of-service",
          name:"termsOfService",
          component: TermsOfService,
          meta: {
            title: ' Terms of Service | Nisus',
          }
        },
        {
          path:"terms-of-service/detail/:termsId",
          name:"termsDetail",
          component: TermsDetail,
          meta: {
            title: ' Terms of Service | Nisus',
          }
        },
        {
          path:"member-criteria",
          name:"memberCriteria",
          component: MemberCriteria,
          meta: {
            title: ' Member Criteria | Nisus',
          }
        },
        {
          path:"member-criteria/detail/:memberId",
          name:"memberDetail",
          component: MemberDetail,
          meta: {
            title: ' Member Criteria Detail | Nisus',
          }
        },
        {
          path:"contact-to-service",
          name:"contactToService",
          component: ContactToService,
          meta: {
            title: ' Contact To Service | Nisus',
          }
        },
        {
          path:"contact-to-service/detail/:contactId",
          name:"contactToServiceDetail",
          component: ContactToServiceDetail,
          meta: {
            title: ' Contact To Service Detail | Nisus',
          }
        },
        {
          path:"information",
          name:"information",
          component: Information,
          meta: {
            title: ' Information | Nisus',
          }
        },
        {
          path:"information/detail/:informationId",
          name:"informationDetail",
          component: InformationDetail,
          meta: {
            title: ' Information Detail | Nisus',
          }
        },
        {
          path:"about-us",
          name:"aboutUs",
          component: AboutUs,
          meta: {
            title: ' About Us | Nisus',
          }
        },
        {
          path:"about-us/detail/:aboutId",
          name:"aboutUsDetail",
          component: AboutUsDetail,
          meta: {
            title: ' About Us Detail | Nisus',
          }
        },
        {
          path:"faq",
          name:"faq",
          component: Faq,
          meta: {
            title: ' FAQ | Nisus',
          }
        },
        {
          path:"faq/detail/:faqId",
          name:"faqDetail",
          component: FaqDetail,
          meta: {
            title: ' FAQ Detail | Nisus',
          }
        },
        {
          path:"administrator",
          name:"administrator",
          component: Administrator,
          meta: {
            title: ' Administrator | Nisus',
          }
        },
        {
          path:"administrator/detail/:administratorId",
          name:"administratorDetail",
          component: AdministratorDetail,
          meta: {
            title: ' Administrator Detail | Nisus',
          }
        },
        {
          path:"role-setting",
          name:"roleSetting",
          component: RoleSetting,
          meta: {
            title: ' Role Setting | Nisus',
          }
        },
        {
          path:"role-setting/detail/:roleId",
          name:"roleSettingDetail",
          component: RoleSettingDetail,
          meta: {
            title: ' Role Setting Detail | Nisus',
          }
        },
        {
          path:"update",
          name:"update",
          component: Update,
          meta: {
            title: ' Update | Nisus',
          }
        },
        {
          path:"update/detail/:updateId",
          name:"updateDetail",
          component: UpdateDetail,
          meta: {
            title: ' Update Detail | Nisus',
          }
        },
      ]
    },
    {
      path:"/delete-account-request",
      component: Body,
      children:[
        {
          path:"",
          name:"deleteAccountRequest",
          component: DeleteAccountRequest,
          meta: {
            title: 'Delete Account Request | Nisus',
          }
        },
      ]
    },
    {
      path:"/node-log-list",
      component: Body,
      children:[
        {
          path:"",
          name:"nodeLogList",
          component: NodeLogList,
          meta: {
            title: 'Node Log List | Nisus',
          }
        },
      ]
    },
    // web view
     { 
      path: '/delete-account',
      name: 'deleteAccount',
      component: DeleteAccount,
      meta: {
          title: 'Delete Account | Nisus',
        }
    }, 
    { 
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: Error404,
      meta: {
          title: 'Error404 | Nisus',
        }
    }, 
  // template
    
    // {
    //     path: '/dashboard',
    //     component: Body,
    //     children: [
    //     {
    //       path: 'default',
    //       name: 'default',
    //       component: Default,
    //       meta: {
    //         title: 'Default Dashboard | Nisus',
    //       }
    //     },
    //     {
    //       path: 'ecommerce',
    //       name: 'ecommerce',
    //       component: Ecommerce,
    //       meta: {
    //         title: 'Ecommerce Dashboard | Nisus',
    //       }
    //     },
    //     {
    //       path: 'online',
    //       name: 'online',
    //       component: Online,
    //       meta: {
    //         title: 'Online Dashboard | Nisus',
    //       }
    //     },
    //     {
    //       path: 'crypto',
    //       name: 'crypto',
    //       component: Crypto,
    //       meta: {
    //         title: 'Crypto Dashboard | Nisus',
    //       }
    //     },
    //     {
    //       path: 'social',
    //       name: 'social',
    //       component: Social,
    //       meta: {
    //         title: 'Social Dashboard | Nisus',
    //       }
    //     }
    //     ]
    //   },
    //   {
    //     path: '/widgets',
    //     component: Body,
    //     meta: {
    //       requiresAuth: true
    //     },
    //     children: [
    //     {
    //       path: 'general',
    //       name: 'general',
    //       component: General,
    //       meta: {
    //         title: 'general Dashboard | Nisus',
    //       }
    //     },
    //     {
    //       path: 'chart',
    //       name: 'chart',
    //       component: Chart,
    //       meta: {
    //         title: 'chart Dashboard | Nisus',
    //       }
    //     },
    //     ]
    //   },
    //   {
    //     path: '/pageLayout',
    //     component: Body,
    //     children: [
    //       {
    //         path: 'boxed',
    //         name: 'boxed',
    //         component: indexBoxed,
    //         meta: {
    //           title: 'Boxed | Nisus',
    //         }
    //       },
    //       {
    //         path: 'darklayout',
    //         name: 'darklayout',
    //         component: indexDarklayout,
    //         meta: {
    //           title: 'Dark Layout | Nisus',
    //         }
    //       }
    //       ,
    //       {
    //         path: 'footerdark',
    //         name: 'footerdark',
    //         component: indexFooterdark,
    //         meta: {
    //           title: 'Footer Dark | Nisus',
    //         }
    //       }
    //       ,
    //       {
    //         path: 'footerlight',
    //         name: 'footerlight',
    //         component: indexFooterlight,
    //         meta: {
    //           title: 'Footer Light | Nisus',
    //         }
    //       }
    //       ,
    //       {
    //         path: 'footerfixed',
    //         name: 'footerfixed',
    //         component: indexFooterfixed,
    //         meta: {
    //           title: 'Footer Fixed | Nisus',
    //         }
    //       }
    //       ,
    //       {
    //         path: 'hidenavscroll',
    //         name: 'hidenavscroll',
    //         component: indexHidenavscroll,
    //         meta: {
    //           title: 'Hide Nav Scroll | Nisus',
    //         }
    //       }
    //       ,
    //       {
    //         path: 'RTL',
    //         name: 'RTL',
    //         component: indexRTL,
    //         meta: {
    //           title: 'RTL | Nisus',
    //         }
    //       }
    //     ]
    //   },
    //   {
    //     path: '/project',
    //     component: Body,
    //     children: [
    //       {
    //         path: 'projectlist',
    //         name: 'projectlist',
    //         component: indexList,
    //         meta: {
    //           title: 'Project list | Nisus',
    //         }
    //       },
    //       {
    //         path: 'create-project',
    //         name: 'createProject',
    //         component: createProject,
    //         meta: {
    //           title: 'Create new | Nisus',
    //         }
    //       }
    //     ]
    //   },
    //   {
    //     path: '/app',
    //     component: Body,
    //     children: [
    //       {
    //         path: 'socialPage',
    //         name: 'socialPage',
    //         component: socialPage,
    //          meta: {
    //             title: 'Social App, | Nisus',
    //           }
    //       },
    //     {
    //       path: 'todo',
    //       name: 'todo',
    //       component: Todo,
    //        meta: {
    //           title: 'Todo | Nisus',
    //         }
    //     },
      
    //     {
    //       path: 'chat',
    //       name: 'chat',
    //       component: chatApp,
    //        meta: {
    //           title: 'Chat | Nisus',
    //         }
    //     },
    //     {
    //       path: 'videochat',
    //       name: 'Video chat',
    //       component: videoChat,
    //        meta: {
    //           title: 'Video Chat | Nisus',
    //         }
    //     },

    //     {
    //       path: 'filemanager',
    //       name: 'File Manager',
    //       component: FileManager,
    //        meta: {
    //           title: 'File Manager, | Nisus',
    //         }
    //     },
    //     {
    //       path: 'kanban',
    //       name: 'Kanban Board',
    //       component: Kanban,
    //        meta: {
    //           title: 'Kanban Board, | Nisus',
    //         }
    //     },
    //     {
    //       path: 'contact',
    //       component: contactView,
    //       name: 'contactView',
    //       meta: {
    //         title: 'Contacts | Nisus',
    //       }
    //     },
    //     {
    //       path:'bookmark',
    //       name:'Bookmark',
    //       component:Bookmark,
    //       meta: {
    //         title: 'Bookmark | Nisus',
    //       }
    //     },
    //     {
    //       path:'task',
    //       name:'Task',
    //       component:Task,
    //       meta: {
    //         title: 'Task | Nisus',
    //       }
    //     },
    //     {
    //       path:'calendar',
    //       name:'Calendar',
    //       component:Calendar,
    //       meta: {
    //         title: 'Calendar | Nisus',
    //       }
    //     }
    //     ]
    //   },
    //   {
    //     path: "/ecommerce",
    //     component: Body,
    //     children: [
    //       {
    //         path: "product",
    //         name: "Product",
    //         component: indexProduct,
    //         meta: {
    //           title: "Products | Nisus",
    //         },
    //       },
    //       {
    //         path: "product/page/:id",
    //         name: "ProductPage",
    //         component: productPage,
    //         meta: {
    //           title: "Products Page | Nisus",
    //         },
    //       },
    //       {
    //         path: "product/list",
    //         name: "productList",
    //         component: productList,
    //         meta: {
    //           title: "Products list | Nisus",
    //         },
    //       },
    //       {
    //         path: "payment/details",
    //         name: "paymentDetails",
    //         component: paymentDetails,
    //         meta: {
    //           title: "Payment Details | Nisus",
    //         },
    //       },
    //       {
    //         path: "order/history",
    //         name: "Order History",
    //         component: orderHistory,
    //         meta: {
    //           title: "OrderHistory | Nisus",
    //         },
    //       },
    //       {
    //         path: "invoice",
    //         name: "Invoice",
    //         component: invoiceView,
    //         meta: {
    //           title: "Invoice | Nisus",
    //         },
    //       },
    //       {
    //         path: "cart",
    //         name: "Cart",
    //         component: cartView,
    //         meta: {
    //           title: "Cart | Nisus", 
    //         },
    //       },
    //       {
    //         path: "wishlist",
    //         name: "Wishlist",
    //         component: wishlistView,
    //         meta: {
    //           title: "Wishlist | Nisus",
    //         },
    //       },
    //       {
    //         path: "checkout",
    //         name: "Checkout",
    //         component: checkoutView,
    //         meta: {
    //           title: "Checkout | Nisus",
    //         },
    //       },
    //       {
    //         path: "pricing",
    //         name: "pricing",
    //         component: pricingView,
    //         meta: {
    //           title: "pricingView | Nisus",
    //         },
    //       },
    
    
    //     ],
    //   },
    //   {
    //     path: '/email',
    //     component: Body,
    //     children: [
    //       {
    //         path: "readMail",
    //         name: "readMail",
    //         component: readMail,
    //         meta: {
    //           title: "Email App | Nisus",
    //         },
    //       },
    //       {
    //         path: "compose",
    //         name: "compose",
    //         component: composeView,
    //         meta: {
    //           title: "Email Compose | Nisus",
    //         },
    //       }
    //     ]
    //   },
    //   {
    //     path: '/users',
    //     component: Body,
    //     children: [
    //       {
    //         path: "profile",
    //         name: "profile",
    //         component: userProfile,
    //         meta: {
    //           title: "Users Profile | Nisus",
    //         },
    //       },
    //       {
    //         path: "edit",
    //         name: "edit",
    //         component: userEdit,
    //         meta: {
    //           title: "Users Edit | Nisus",
    //         },
    //       },
    //       {
    //         path: "cards",
    //         name: "cards",
    //         component: userCards,
    //         meta: {
    //           title: "Users Cards | Nisus",
    //         },
    //       }
    //     ]
    //   },
    //   {
    //     path:"/form",
    //     component:Body,
    //     children:[
    //       {
    //         path:"validation",
    //         name:"formValidation",
    //         component:formValidation,
    //         meta: {
    //           title: "Form Validation | Nisus",
    //         },
    //       },
    //       {
    //         path:"inputs",
    //         name:"basicInput",
    //         component:base_Input,
    //         meta: {
    //           title: "Base Inputs | Nisus",
    //         },
    //       },
    //       {
    //         path:"checkbox-radio",
    //         name:"checkbox & radio",
    //         component:checkbox_radio,
    //         meta: {
    //           title: "Checkbox & Radio | Nisus",
    //         },
    //       },
    //       {
    //         path:"input-groups",
    //         name:"input Groups",
    //         component:input_groups,
    //         meta: {
    //           title: "Input Groups | Nisus",
    //         },
    //       },
    //       {
    //         path:"mega-options",
    //         name:"megaOptions",
    //         component:megaOptions,
    //         meta: {
    //           title: "Mega Options | Nisus",
    //         },
    //       },
    //       {
    //         path:"select2",
    //         name:"select2",
    //         component:select2,
    //         meta: {
    //           title: "Select2 | Nisus",
    //         },
    //       },
    //       {
    //         path:"switch",
    //         name:"switch",
    //         component:switch_From,
    //         meta: {
    //           title: "Switch | Nisus",
    //         },
    //       },
    //       {
    //         path:"touchspin",
    //         name:"touchspin",
    //         component:touchspin_Form,
    //         meta: {
    //           title: "Touchspin | Nisus",
    //         },
    //       },
    //       {
    //         path:"typeahead",
    //         name:"typeahead",
    //         component:typeahead_Form,
    //         meta: {
    //           title: "Typeahead | Nisus",
    //         },
    //       },
    //       {
    //         path:"clipboard",
    //         name:"clipboard",
    //         component:clipboard_Form,
    //         meta: {
    //           title: "Clipboard | Nisus",
    //         },
    //       },
    //       {
    //         path:"wizard2",
    //         name:"form_wizard2",
    //         component:form_wizard2,
    //         meta: {
    //           title: "Form Wizard2 | Nisus",
    //         },
    //       },
    //       {
    //         path:"wizard1",
    //         name:"formwizard",
    //         component:form_wizard1,
    //         meta: {
    //           title: "Form Wizard1 | Nisus",
    //         },
    //       },
    //       {
    //         path:"wizard3",
    //         name:"formwizard3",
    //         component:form_wizard3,
    //         meta: {
    //           title: "Form Wizard3 | Nisus",
    //         },
    //       },
    //       {
    //         path:"defaultforms",
    //         name:"defaultforms",
    //         component:defaultforms,
    //         meta: {
    //           title: "Default Forms | Nisus",
    //         },
    //       },
    //       {
    //         path:"datepicker",
    //         name:"datepicker",
    //         component:datepicker,
    //         meta: {
    //           title: "Datepicker | Nisus",
    //         },
    //       },
          
    //     ]
    //   },
    //   {
    //     path: "/advance",
    //     component: Body,
    //     children: [
    //         {
    //             path: "scrollable",
    //             name: "Scrollable",
    //             component: Scrollable,
    //             meta: {
    //                 title: "Scrollable | Nisus",
    //             },
    //         },
    //         {
    //           path: "treeView",
    //           name: "Tree",
    //           component: Tree,
    //           meta: {
    //               title: "Tree | Nisus",
    //           },
    //       },
    //         {
    //           path: "notify",
    //           name: "notify",
    //           component: Notify,
    //           meta: {
    //               title: "Scrollable | Nisus",
    //           },
    //         },
    //         {
    //           path: "rating",
    //           name: "Rating",
    //           component: Rating,
    //           meta: {
    //               title: "Rating | Nisus",
    //           },
    //       },
    //       {
    //         path: "dropzone",
    //         name: "dropzone",
    //         component: dropzone,
    //         meta: {
    //             title: "dropzone | Nisus",
    //         },
    //     },
    //     {
    //       path: "tour",
    //       name: "Tour",
    //       component: Tour,
    //       meta: {
    //           title: "Tour | Nisus",
    //       },
    //   },
    //         {
    //             path: "sweetalert",
    //             name: "Sweetalert",
    //             component: Sweetalert,
    //             meta: {
    //                 title: "Sweetalert | Nisus",
    //             },
    //         },
    //         {
    //           path: "animatedModal",
    //           name: "animatedModal",
    //           component: animatedModal,
    //           meta: {
    //               title: "Sweetalert | Nisus",
    //           },
    //       },
    //       {
    //         path: "owlCarousel",
    //         name: "owlCarousel",
    //         component: owlCarousel,
    //         meta: {
    //             title: "Sweetalert | Nisus",
    //         },
    //     },
           
    //         {
    //             path: "ribbons",
    //             name: "Ribbons",
    //             component: Ribbons,
    //             meta: {
    //                 title: "Ribbons | Nisus",
    //             },
    //         },
    //         {
    //           path: "pagination",
    //           name: "Pagination",
    //           component: Pagination,
    //           meta: {
    //               title: "Pagination | Nisus",
    //           },
    //       },
    //       {
    //         path: "breadcrumb",
    //         name: "Breadcrumb",
    //         component: Breadcrumb,
    //         meta: {
    //             title: "Breadcrumb | Nisus",
    //         },
    //     },
    //         {
    //             path: "range-slider",
    //             name: "Rangeslider",
    //             component: Rangeslider,
    //             meta: {
    //                 title: "Rangeslider | Nisus",
    //             },
    //         },
    //         {
    //           path: "image-cropper",
    //           name: "ImageCropper",
    //           component: ImageCropper,
    //           meta: {
    //               title: "ImageCropper | Nisus",
    //           },
    //       },
            
    //         {
    //             path: "sticky",
    //             name: "Sticky",
    //             component: Sticky,
    //             meta: {
    //                 title: "Sticky | Nisus",
    //             },
    //         },
    //         {
    //           path: "basicCard",
    //           name: "basicCard",
    //           component: BasicCard,
    //           meta: {
    //               title: "Sticky | Nisus",
    //           },
    //       },
    //       {
    //         path: "creativeCard",
    //         name: "creativeCard",
    //         component: CreativeCard,
    //         meta: {
    //             title: "Sticky | Nisus",
    //         },
    //     },
    //     {
    //       path: "TabbedCard",
    //       name: "TabbedCard",
    //       component: TabbedCard,
    //       meta: {
    //           title: "Sticky | Nisus",
    //       },
    //   },
    //   {
    //     path: "DraggableCard",
    //     name: "DraggableCard",
    //     component: DraggableCard,
    //     meta: {
    //         title: "Sticky | Nisus",
    //     },
    // },
    //         {
    //             path: "timeline1",
    //             name: "timeline",
    //             component: timeLine,
    //             meta: {
    //                 title: "Toastr | Nisus",
    //             },
    //         },
    //     ],
    // },
    // {
    //   path:"/chart",
    //   component:Body,
    //   children:[
    //     {
    //       path:"google",
    //       name:"googlechart",
    //       component:google_chart,
    //       meta: {
    //         title: "Google Chart | Nisus",
    //     },
    //     },
    //     {
    //       path:"apexChart",
    //       name:"apexchart",
    //       component:apex_chart,
    //       meta: {
    //         title: "ApexChart | Nisus",
    //     },
    //     },
    //     {
    //       path:"chartist",
    //       name:"chartist",
    //       component:chartist_chart,
    //       meta: {
    //         title: "Chartist | Nisus",
    //     },
    //     },
  
    //   ]
    // },
    // {
    //   path: '/pages',
    //   component: Body,
    //   children:[
    //     {
    //       path:'/pages/faq',
    //       name:'Faq',
    //       component:Faq,
    //       meta: {
    //       title: 'Faq | Nisus',
    //     }
    //     },
    //       {
    //         path:'/pages/support',
    //         name:'Support',
    //         component:Support,
    //         meta: {
    //         title: 'Support | Nisus',
    //       }
    //       },
    //       {
    //         path:'sample-page',
    //         name:'sample-page',
    //         component:sample_page,
    //         meta: {
    //             title: 'sample-page | Nisus',
    //           }
    //       },
    //       {
    //   path:"search",
    //   name:"search",
    //   component:serchIndex
    // },
        
    //   ]
    // },
    // {
    //   path: '/uikits',
    //   component: Body,
    //   children: [
    //   {
    //     path: 'alert',
    //     name: 'alert',
    //     component: alert,
    //     meta: {
    //       title: 'Alert | Nisus',
    //     }
    //   },
    //   {
    //     path: 'helper-classes',
    //     name: 'Helperclasses',
    //     component: Helperclasses,
    //     meta: {
    //       title: 'Helperclasses | Nisus',
    //     }
    //   },
    //   {
    //     path: 'avatars',
    //     name: 'Avatars',
    //     component: Avatars,
    //     meta: {
    //       title: 'Avatars | Nisus',
    //     }
    //   },
    //   {
    //     path: 'grid',
    //     name: 'Grid',
    //     component: Grid,
    //     meta: {
    //       title: 'Grid | Nisus',
    //     }
    //   },
    //   {
    //     path: 'tag-pills',
    //     name: 'Tagpills',
    //     component: Tagpills,
    //     meta: {
    //       title: 'Tagpills | Nisus',
    //     }
    //   },
    //   {
    //     path: 'typography',
    //     name: 'Typography',
    //     component: Typography,
    //     meta: {
    //       title: 'Typography | Nisus',
    //     }
    //   },
    //   {
    //     path: 'progress-bar',
    //     name: 'Progressbar',
    //     component: Progressbar,
    //     meta: {
    //       title: 'Progressbar | Nisus',
    //     }
    //   },
    //   {
    //     path: 'modal',
    //     name: 'Modal',
    //     component: Modal,
    //     meta: {
    //       title: 'Modal | Nisus',
    //     }
    //   },
    //   {
    //     path: 'popover',
    //     name: 'Popover',
    //     component: Popover,
    //     meta: {
    //       title: 'Popover | Nisus',
    //     }
    //   },
    //   {
    //     path: 'tooltip',
    //     name: 'Tooltip',
    //     component: Tooltip,
    //     meta: {
    //       title: 'Tooltip | Nisus',
    //     }
    //   },
    //   {
    //     path: 'loader',
    //     name: 'Spinners',
    //     component: Spinners,
    //     meta: {
    //       title: 'Spinners | Nisus',
    //     }｀
    //   },
    //   {
    //     path: 'dropdown',
    //     name: 'Dropdown',
    //     component: Dropdown,
    //     meta: {
    //       title: 'Dropdown | Nisus',
    //     }
    //   },
    //   {
    //     path: 'accordion',
    //     name: 'Accordion',
    //     component: Accordion,
    //     meta: {
    //       title: 'Accordion | Nisus',
    //     }
    //   },
    //   {
    //     path: "bootstraptab",
    //     name: "bootstraptabPage",
    //     component: bootstraptabPage,
    //     meta: {
    //       title: "tab | Nisus",
    //     },
    //   },
    //   {
    //     path: "linetab",
    //     name: "linetabPage",
    //     component: linetabPage,
    //     meta: {
    //       title: "Accordion | Nisus",
    //     },
    //   },
    //   {
    //     path: 'box-shadow',
    //     name: 'Boxshadow',
    //     component: Boxshadow,
    //     meta: {
    //       title: 'Boxshadow | Nisus',
    //     }
    //   },
    //   {
    //     path: 'lists',
    //     name: 'Lists',
    //     component: Lists,
    //     meta: {
    //       title: 'Lists | Nisus',
    //     }
    //   }
    //   ]
    // },
    // {
    //   path: "/animation",
    //   component: Body,
    //   children: [
    //       {
    //           path: "animate",
    //           name: "animate",
    //           component: animate,
    //           meta: {
    //               title: "animate | Nisus",
    //           },
    //       },
    //       {
    //         path: "scroll",
    //         name: "scroll",
    //         component: scroll,
    //         meta: {
    //             title: "scroll | Nisus",
    //         },
    //     },
    //       {
    //         path: "AOS",
    //         name: "aos",
    //         component: aos,
    //         meta: {
    //             title: "aos | Nisus",
    //         },
    //       },
     
    //     {
    //       path: "wow",
    //       name: "wow",
    //       component: wow,
    //       meta: {
    //           title: "wow | Nisus",
    //       },
    //   },
    //   {
    //     path: "tilt",
    //     name: "tilt",
    //     component: tilt,
    //     meta: {
    //         title: "tilt | Nisus",
    //     },
    // },
    //     ],
    // },
    // {
    //   path:"/builder",
    //   component: Body,
    //   children:[
    //     {
    //       path: "form1",
    //       name: "Form Builder1",
    //       component: form1,
    //       meta: {
    //         title: "from1 | Nisus",
    //       },
    //     },
    //     {
    //       path: "form2",
    //       name: "Form Builder2",
    //       component: form2,
    //       meta: {
    //         title: "from2 | Nisus",
    //       },
    //     }
    //   ]
    // },
    // {
    //   path: '/icons',
    //   component: Body,
    //   children:[
    //   {
    //     path: 'flag',
    //     name: 'Flag',
    //     component: Flag,
    //      meta: {
    //         title: 'Flag | Nisus',
    //       }
    //   },
    //   {
    //     path: 'ico',
    //     name: 'Ico',
    //     component: Ico,
    //      meta: {
    //         title: 'Ico | Nisus',
    //       }
    //   },
    //   {
    //     path: 'themify',
    //     name: 'Themify',
    //     component: Themify,
    //      meta: {
    //         title: 'Themify | Nisus',
    //       }
    //   },
    //   {
    //     path: 'fontawesome',
    //     name: 'Fontawesome',
    //     component: Fontawesome,
    //      meta: {
    //         title: 'Fontawesome | Nisus',
    //       }
    //   },
    //   {
    //     path: 'feather_icon',
    //     name: 'FeatherIcon',
    //     component: FeatherIcon,
    //      meta: {
    //         title: 'FeatherIcon | Nisus',
    //       }
    //   },
    //   {
    //     path: 'whether',
    //     name: 'WhetherIcon',
    //     component: WhetherIcon,
    //      meta: {
    //         title: 'WhetherIcon | Nisus',
    //       }
    //   }
    //   ]
    // },
    // {
    //   path: '/buttons',
    //   component: Body,
    //   children:[
    //   {
    //     path: 'default',
    //     name: 'default_buttons',
    //     component: Default_buttons,
    //      meta: {
    //         title: 'Default_buttons | Nisus',
    //       }
    //   },
    //   {
    //     path: 'flat',
    //     name: 'flat_buttons',
    //     component: Flat_buttons,
    //      meta: {
    //         title: 'Flat_buttons | Nisus',
    //       }
    //   },
    //   {
    //     path: 'edge',
    //     name: 'edge_buttons',
    //     component: Edge_buttons,
    //      meta: {
    //         title: 'Edge_buttons | Nisus',
    //       }
    //   },
    //   {
    //     path: 'raised',
    //     name: 'raised_buttons',
    //     component: Raised_buttons,
    //      meta: {
    //         title: 'Raised_buttons | Nisus',
    //       }
    //   },
    //   {
    //     path: 'group',
    //     name: 'group_buttons',
    //     component: Group_buttons,
    //      meta: {
    //         title: 'Group_buttons | Nisus',
    //       }
    //   }
    //   ]
    // },
    // {
    //   path: '/knowledgebase',
    //   component: Body,
    //   children: [
    //     {
    //       path: "knowledgebase",
    //       name: 'knowledge',
    //       component: knowledge,
    //       meta: {
    //         title: 'Knowledgebase | Nisus',
    //       }
    //     },
    //     {
    //       path: 'category',
    //       name: 'category_knowledge',
    //       component: category_knowledge
    //     },
    //     {
    //       path: 'detail',
    //       name: 'detail_knowledge',
    //       component: detail_knowledge
    //     }
    //   ]
    // },
    // {
    //   path:'/error-400',
    //   name:'Error400',
    //   component:Error400,
    //   meta: {
    //       title: 'Error400 | Nisus',
    //     }
    // },
    // {
    //   path:'/error-401',
    //   name:'Error401',
    //   component:Error401,
    //   meta: {
    //       title: 'Error401 | Nisus',
    //     }
    // },
    // {
    //   path:'/error-403',
    //   name:'Error403',
    //   component:Error403,
    //   meta: {
    //       title: 'Error403 | Nisus',
    //     }
    // },
    // {
    //   path:'/error-404',
    //   name:'Error404',
    //   component:Error404,
    //   meta: {
    //       title: 'Error404 | Nisus',
    //     }
    // },
    // {
    //   path:'/error-500',
    //   name:'Error500',
    //   component:Error500,
    //   meta: {
    //       title: 'Error500 | Nisus',
    //     }
    // },
    // {
    //   path:'/error-503',
    //   name:'Error503',
    //   component:Error503,
    //   meta: {
    //       title: 'Error503 | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/login/one',
    //   name:'LoginOne',
    //   component:LoginOne,
    //   meta: {
    //       title: 'LoginOne | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/login/two',
    //   name:'LoginTwo',
    //   component:LoginTwo,
    //   meta: {
    //       title: 'LoginTwo | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/login/validate',
    //   name:'LoginValidate',
    //   component:LoginValidate,
    //   meta: {
    //       title: 'LoginValidate | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/login/tooltip',
    //   name:'LoginTooltip',
    //   component:LoginTooltip,
    //   meta: {
    //       title: 'LoginTooltip | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/login/sweetalert',
    //   name:'LoginSweetalert',
    //   component:LoginSweetalert,
    //   meta: {
    //       title: 'LoginTooltip | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/register/image',
    //   name:'RegisterImage',
    //   component:RegisterImage,
    //   meta: {
    //       title: 'RegisterImage | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/register/image2',
    //   name:'RegisterImage2',
    //   component:RegisterImage2,
    //   meta: {
    //       title: 'RegisterImage2 | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/unlockuser',
    //   name:'Unlock',
    //   component:Unlock,
    //   meta: {
    //       title: 'Unlock | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/forgetpassword',
    //   name:'ForgetPassword',
    //   component:ForgetPassword,
    //   meta: {
    //       title: 'ForgetPassword | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/resetpassword',
    //   name:'ResetPassword',
    //   component:ResetPassword,
    //   meta: {
    //       title: 'ResetPassword | Nisus',
    //     }
    // },
    // {
    //   path:'/authentication/maintenance',
    //   name:'Maintenance',
    //   component:Maintenance,
    //   meta: {
    //       title: 'ResetPassword | Nisus',
    //     }
    // },
    // {
    //   path:'/comingsoon/comingsoon-image',
    //   name:'ComingsoonImage',
    //   component:ComingsoonImage,
    //   meta: {
    //       title: 'ComingsoonImage | Nisus',
    //     }
    // },
    // {
    //   path:'/comingsoon/comingsoon-simple',
    //   name:'ComingsoonSimple',
    //   component:ComingsoonSimple,
    //   meta: {
    //       title: 'ComingsoonSimple | Nisus',
    //     }
    // },
    // {
    //   path:'/comingsoon/comingsoon-video',
    //   name:'ComingsoonVideo',
    //   component:ComingsoonVideo,
    //   meta: {
    //       title: 'ComingsoonVideo | Nisus',
    //     }
    // },
 
    // {
    //   path: '/editor',
    //   component: Body,
    //   children:[
    //     {
    //       path: 'simple-editor',
    //       name: 'SimpleEditor',
    //       component:SimpleEditor,
    //       meta: {
    //         title: 'SimpleEditor | Nisus',
    //       }
    //     },
    //     {
    //       path: 'ck-editor',
    //       name: 'CkEditor',
    //       component:CkEditor,
    //        meta: {
    //         title: 'CkEditor | Nisus',
    //       }
          
    //     }
    //   ]
    // },
    // {
    //   path:'/maps',
    //   component: Body,
    //   children:[
    //     {
    //       path: 'vue-google-maps',
    //       name: 'GoogleMaps',
    //       component:GoogleMaps,
    //        meta: {
    //         title: 'oogleMaps | Nisus',
    //       }
    //     },
    //     {
    //       path: 'vue-leaflet-maps',
    //       name: 'VueLeaflet',
    //       component:VueLeaflet,
    //        meta: {
    //         title: 'ueLeaflet | Nisus',
    //       }
    //     }
    //   ]
    // },
    // {
    //   path:"/learning",
    //   component:Body,
    //   children:[
    //     {
    //       path:"list",
    //       name:"learninglist",
    //       component:learning_list,
    //       meta: {
    //         title: 'Learning List | Nisus',
    //       }
    //     },
    //     {
    //       path:"details/:id",
    //       name:"coursedetailed",
    //       component:coursedetailed,
    //       meta: {
    //         title: 'Detailed Course | Nisus',
    //       }
    //     }
    //   ]
    // },
    // {
    //   path:"/job",
    //   component:Body,
    //   children:[
    //     {
    //       path:"card",
    //       name:"cardview",
    //       component:job_card,
    //       meta: {
    //         title: 'Card View | Nisus',
    //       }
    //     },
    //     {
    //       path:"list",
    //       name:"listview",
    //       component:job_list,
    //       meta: {
    //         title: 'List View | Nisus',
    //       }
    //     },
    //     {
    //       path:"/job/details/:id",
    //       name:"jobdetails",
    //       component:job_details,
    //       props:true,
    //       meta: {
    //         title: 'Job Details | Nisus',
    //       }
    //     },
    //     {
    //       path:"apply/:id",
    //       name:"jobapply",
    //       component:job_apply,
    //       meta: {
    //         title: 'Apply | Nisus',
    //       }
    //     }
    //   ]},
    //   {
    //     path:"/blog",
    //     component:Body,
    //     children:[
    //       {
    //         path:"details",
    //         name:"blog-detail",
    //         component:blog_detail,
    //         meta: {
    //           title: 'Blog Details | Nisus',
    //         }
    //       },
    //       {
    //         path:"single",
    //         name:"blog_single",
    //         component:blog_single,
    //         meta: {
    //           title: 'Blog Single | Nisus',
    //         }
    //       },
    //       {
    //         path:"add",
    //         name:"blog_add",
    //         component:blog_add,
    //         meta: {
    //           title: 'Add Blog | Nisus',
    //         }
    //       }
          
    //     ]
    //   },
    //   {
    //     path:"/gallery",
    //     component:Body,
    //     children:[
    //       {
    //         path:"grid-gallery",
    //         name:"gridgallery",
    //         component:grid_gallery,
    //         meta: {
    //           title: 'Grid Gallery | Nisus',
    //         }
    //       },
    //       {
    //         path:"gallery-desc",
    //         name:"griddesc",
    //         component:grid_desc,
    //         meta: {
    //           title: 'Grid Gallery With Desc | Nisus',
    //         }
    //       },
    //       {
    //         path:"hover-effect",
    //         name:"hovergallery",
    //         component:hover_gallery,
    //         meta: {
    //           title: 'Hover Effect | Nisus',
    //         }
    //       },
    //       {
    //         path:"gallery-masonary",
    //         name:"masonry-gallery",
    //         component:masonry_gallery,
    //         meta: {
    //           title: 'Masonary Gallery | Nisus',
    //         }
    //       },
    //       {
    //         path:"gallery-masonary-desc",
    //         name:"masonary-desc",
    //         component:masonary_desc,
    //         meta: {
    //           title: 'Masonary Gallery Desc | Nisus',
    //         }
    //       }
      
    //     ]
    //   },
    //   {
    //     path: '/auth',
    //     children: [
    //     {
    //       path: 'login',
    //       name: 'Login 1',
    //       component: login,
    //       meta: {
    //         title: ' login | Nisus',
    //       }
    //     },
    //     {
    //       path: 'register',
    //       name: 'register 1',
    //       component: Register,
    //       meta: {
    //         title: ' Register | Nisus',
    //       }
    //     }
    //     ]
    //   },
    //   {
    //     path:"/table",
    //     component:Body,
    //     children:[
    //       {
    //         path:"basic",
    //         name:"basic1",
    //         component:BootstrapBasic,
    //         meta: {
    //           title: ' Basic Tables | Nisus',
    //         }
    //       },
    //       {
    //         path:"tableComponent",
    //         name:"tableComponent",
    //         component:tableComponent,
    //         meta: {
    //           title: ' Table Components | Nisus',
    //         }
    //       },
          
    //       {
    //         path:"/datatable-basic",
    //         name:"datatable",
    //         component:DatatableBasic,
    //         meta: {
    //           title: ' Basic Init | Nisus',
    //         }
    //       },
     
    //     ]
    //   },
]
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
    routes,
  })
  router.beforeEach((to, from, next) => {
    // remove modal backdrop if one exists
    let modalBackground = document.querySelectorAll('.modal-backdrop')
    if (modalBackground.length > 0) {
      modalBackground.forEach(backdrop => backdrop.remove());
    }

    if(to.meta.title)
      document.title = to.meta.title;

    // Check if accessToken is null
    const accessToken = store.state.user.accessToken;
    if (!accessToken && to.path !== '/auth/login') {
      return next('/auth/login');
    }

    next();
  });
  export default router