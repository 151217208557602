<template>
    <Breadcrumbs title="Dashboard" />
    <div class="container-fluid">
        <div class="row g-4">
            <div class="col-xl-4">
                <div class="card map-gradient h-100">
                    <div class="card-body h-100">
                         <h6 class="text-white">Country map with states</h6>
                        <div class="d-flex gap-3 justify-content-between align-items-center">
                            <div class="select-wrap">
                                <multiselect class="mb-2" v-model="selected.country" :options="options.country" :searchable="true" 
                                :close-on-select="true" :show-labels="false" :allow-empty="false" placeholder="Country"  
                                @update:modelValue="val => selectChange(val, 'country')"></multiselect>
                                <multiselect :multiple="true"  v-model="selected.state" :options="options.state" :searchable="true" 
                                :close-on-select="true" :show-labels="false" placeholder="State" 
                                @update:modelValue="val => selectChange(val, 'state')"></multiselect>
                            </div>
                            <img width="90" src="@/assets/images_gc/img_country-map@2x.png" alt="map">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body h-100">
                      <div class="d-flex justify-content-between gap-2">
                        <h6>Termite Count</h6>  
                        <h6 v-if="countInfo.termite.total">{{ $formatNumber(countInfo.termite.total) }}</h6>
                      </div>
                         <div>
                            <apexchart v-if="termiteCountOptions.xaxis.categories.length > 0" height="75" type="line" :options="termiteCountOptions" :series="termiteCountSeries"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body h-100">
                      <div class="d-flex justify-content-between gap-2">
                        <h6>Leakage Count</h6>  
                        <h6 v-if="countInfo.leakage.total">{{ $formatNumber(countInfo.leakage.total) }}</h6>
                      </div>
                         <div>
                            <apexchart v-if="leakageCountOptions.xaxis.categories.length > 0" height="75"  type="line" :options="leakageCountOptions" :series="leakageCountSeries"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-3">
                <div class="card position-relative">
                  <div class="count-icon r">
                    <vue-feather type="users" stroke="#f73164" size="28" class="inner-icon"></vue-feather>
                  </div>
                    <div class="card-body">
                        <h6 class="mb-3">User Count</h6>
                        <h5>{{ $formatNumber(countInfo.user.total) }}</h5>
                        <p class="text-danger">
                          <vue-feather v-if="countInfo.user.percent > 0" type="arrow-up" size="16"></vue-feather>
                          <vue-feather v-if="countInfo.user.percent < 0" type="arrow-down" size="16" ></vue-feather>
                           <span>{{ countInfo.user.percent }}%</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3">
                <div class="card position-relative">
                  <div class="count-icon g">
                    <vue-feather type="share-2" stroke="#08B258" size="28" class="inner-icon"></vue-feather>
                  </div>
                    <div class="card-body">
                        <h6 class="mb-3">GW Count</h6>
                        <h5>{{ $formatNumber(countInfo.gateway.total) }}</h5>
                         <p class="text-logo-green">
                          <vue-feather v-if="countInfo.gateway.percent > 0" type="arrow-up" size="16"></vue-feather>
                          <vue-feather v-if="countInfo.gateway.percent < 0" type="arrow-down" size="16" ></vue-feather>
                           <span>{{ countInfo.gateway.percent }}%</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3">
                <div class="card position-relative">
                  <div class="count-icon y">
                    <vue-feather type="cpu" stroke="#ffc107" size="28" class="inner-icon"></vue-feather>
                  </div>
                    <div class="card-body">
                        <h6 class="mb-3">Sensor Count</h6>
                         <h5>{{ $formatNumber(countInfo.sensor.total) }}</h5>
                         <p class="text-warning">
                          <vue-feather v-if="countInfo.sensor.percent > 0" type="arrow-up" size="16"></vue-feather>
                          <vue-feather v-if="countInfo.sensor.percent < 0" type="arrow-down" size="16" ></vue-feather>
                           <span>{{ countInfo.sensor.percent }}%</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3">
                <div class="card position-relative">
                  <div class="count-icon b">
                    <vue-feather type="cast" stroke="#0d6efd" size="28" class="inner-icon"></vue-feather>
                  </div>
                    <div class="card-body">
                        <h6 class="mb-3">Service Ticket Count</h6>
                         <h5>{{ $formatNumber(countInfo.service_ticket.total) }}</h5>
                         <p class="text-primary">
                          <vue-feather v-if="countInfo.service_ticket.percent > 0" type="arrow-up" size="16"></vue-feather>
                          <vue-feather v-if="countInfo.service_ticket.percent < 0" type="arrow-down" size="16" ></vue-feather>
                           <span>{{ countInfo.service_ticket.percent }}%</span>
                        </p>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-8">
                <div class="card h-100">
                    <div class="card-body h-100">
                      <div class="d-flex flex-wrap justify-content-between gap-2 mb-4">
                        <h6>Overall Installation(Months for GW)</h6>
                        <ul class="d-flex align-items-center gap-3">
                            <li>
                                <span class="data-circle bg-installed"></span>
                                <span class="ms-1">Installed</span>
                            </li>
                            <li>
                                <span class="data-circle bg-activated"></span>
                                <span class="ms-1">Activated</span>
                            </li>
                        </ul>
                      </div>
                        <div class="d-flex flex-wrap flex-xl-nowrap gap-4">
                            <div class="flex-grow-1">
                              <apexchart v-if="overallOptions.xaxis.categories.length > 0" 
                                height="260" type="bar" :options="overallOptions" :series="overallSeries"></apexchart>
                            </div>
                            <div class="light-card-wrap">
                              <div class="light-card overall-card mb-3">
                                  <div class="svg-box">
                                      <vue-feather type="share-2" fill="#d2ede0" stroke="#d2ede0"></vue-feather>
                                  </div>
                                  <div>
                                      <span>Installed Count</span>
                                      <h6 class="mt-1 mb-0">{{ overallCount.installed }}</h6>
                                  </div>
                              </div>
                              <div class="light-card overall-card">
                                  <div class="svg-box">
                                      <vue-feather type="share-2" fill="#08B258" stroke="#08B258"></vue-feather>
                                  </div>
                                  <div>
                                      <span>Activated Count</span>
                                      <h6 class="mt-1 mb-0">{{ overallCount.activated }}</h6>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body h-100">
                        <h6 class="mb-4">Firmware History</h6>
                        <div class="firmware-overflow">
                            <div v-for="(item, index) in firmwareHistory" :key="item.type + index" 
                            class="d-flex justify-content-between align-items-start flex-column flex-sm-row align-items-sm-center gap-2" 
                            :class="{ 'mb-3': index !== firmwareHistory.length - 1 }">
                              <div class="d-flex align-items-center gap-2">
                                <div class="firmware-icon rounded">
                                  <vue-feather type="cpu" size="20" class="text-white"></vue-feather>
                                </div>
                                <div>
                                  <p class="mb-0">{{ item.type }}</p>
                                  <small class="text-muted">{{ item.publish_at }}</small>
                                </div>
                              </div>
                              <p class="flex-shrink-0 mb-0 text-logo-green">{{ item.important }}</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body h-100">
                        <h6 class="mb-4">Termite Treatment</h6>
                        <div>
                            <apexchart v-if="termiteTreatmentSeries.length > 0" type="pie" :options="termiteTreatmentOptions" :series="termiteTreatmentSeries"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body h-100">
                        <h6 class="mb-4">Leakage Treatment</h6>
                        <div>
                            <apexchart v-if="leakageTreatmentSeries.length > 0" type="pie" :options="leakageTreatmentOptions" :series="leakageTreatmentSeries"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body h-100">
                        <h6>Users Trend</h6>
                        <div>
                            <apexchart v-if="usersTrendOptions.xaxis.categories.length > 0" type="line" :options="usersTrendOptions" :series="usersTrendSeries"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Service Ticket</h6>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                    <tr>
                                        <th scope="col" width="140" @click="sortList('signal')" class="cursor-p">
                                            <div class="d-flex justify-content-between align-items-center gap-2">
                                                <p class="mb-0">Light Signal</p>
                                                <div>
                                                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('signal') && listRequest.order === 'asc' }"></i>
                                                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('signal') && listRequest.order === 'desc' }"></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" @click="sortList('staff')" class="cursor-p">
                                            <div class="d-flex justify-content-between align-items-center gap-2">
                                                <p class="mb-0">Staff</p>
                                                <div>
                                                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('staff') && listRequest.order === 'asc' }"></i>
                                                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('staff') && listRequest.order === 'desc' }"></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" @click="sortList('assigned_at')" class="cursor-p">
                                            <div class="d-flex justify-content-between align-items-center gap-2">
                                                <p class="mb-0">Assigned at</p>
                                                <div>
                                                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('assigned_at') && listRequest.order === 'asc' }"></i>
                                                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('assigned_at') && listRequest.order === 'desc' }"></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" @click="sortList('status')" class="cursor-p">
                                            <div class="d-flex justify-content-between align-items-center gap-2">
                                                <p class="mb-0">Status</p>
                                                <div>
                                                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                                                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" @click="sortList('owner')" class="cursor-p">
                                            <div class="d-flex justify-content-between align-items-center gap-2">
                                                <p class="mb-0">Customer Name</p>
                                                <div>
                                                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('owner') && listRequest.order === 'asc' }"></i>
                                                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('owner') && listRequest.order === 'desc' }"></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" @click="sortList('city')" class="cursor-p">
                                            <div class="d-flex justify-content-between align-items-center gap-2">
                                                <p class="mb-0">City</p>
                                                <div>
                                                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('city') && listRequest.order === 'asc' }"></i>
                                                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('city') && listRequest.order === 'desc' }"></i>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in serviceList" :key="item.id">
                                        <td>
                                            <div class="light-signal" :class="item.signal"></div>
                                        </td>
                                        <td v-if="item.staff">{{ item.staff.first_name }} {{ item.staff.last_name }}</td>
                                        <td>{{ item.assigned_at }}</td>
                                        <td>{{ item.status }}</td>
                                        <td v-if="item.owner">{{ item.owner.first_name }} {{ item.owner.last_name }}</td>
                                        <td>{{ item.city }}</td>
                                        <td>
                                            <router-link v-if="item.staff" :to="{ name: 'staffViewDetail', params: { staffId: item.staff.id }, query: { status: item.status } }">View Details</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
    data() {
        return {
            listRequest: {
                order_by: "",
                order: "",
                page: 1,
                limit: 10,
            },
            total: 0,
            options: {
                country: [],
                state: [],
            },
            selected: {
                country: 'United States',
                state: '',
            },
            countInfo: {
                gateway: {},
                leakage: {},
                sensor: {},
                service_ticket: {},
                status_code: {},
                termite: {},
                user: {}
            },
            termiteCountOptions: {
                chart: {
                    // width: 200,
                    // height: 150,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 5,
                        left: 0,
                        blur: 3,
                        color: '#16C7F9',
                        opacity: 0.3
                    },
                    zoom: {
                        enabled: false
                    }
                },
                colors: ["#16C7F9"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'smooth'
                },
                grid: {
                    show: false
                },
                tooltip: {
                    x: {
                        show: false,
                    },
                    y: {
                        show: false,
                    },
                    z: {
                        show: false,
                    },
                    marker: {
                        show: false
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                // responsive: [
                //     {
                //         breakpoint: 1780,
                //         options: {
                //             chart: {
                //                 width: 180,
                //             }
                //         },
                //     },
                // ]
            },
            termiteCountSeries: [
                {
                    name: "Termite Count",
                    data: []
                }
            ],
            leakageCountOptions: {
                chart: {
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 5,
                        left: 0,
                        blur: 3,
                        color: '#16C7F9',
                        opacity: 0.3
                    },
                    zoom: {
                        enabled: false
                    }
                },
                colors: ["#16C7F9"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'smooth'
                },
                grid: {
                    show: false
                },
                tooltip: {
                    x: {
                        show: false,
                    },
                    y: {
                        show: false,
                    },
                    z: {
                        show: false,
                    },
                    marker: {
                        show: false
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
            },
            leakageCountSeries: [
                {
                    name: "Leakage Count",
                    data: []
                }
            ],
            overallCount: {
                installed: null,
                activated: null,
            },
            firmwareHistory: [],
            // reportTreatment: {
            //     tm: {
            //         solvedCount: null,
            //         unsolvedCount: null,
            //     },
            //     lk: {
            //         solvedCount: null,
            //         unsolvedCount: null,
            //     },
            // },
            usersTrendOptions: {
                chart: {
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 5,
                        left: 0,
                        blur: 3,
                        color: '#16C7F9',
                        opacity: 0.3
                    },
                    zoom: {
                        enabled: false
                    }
                },
                colors: ["#16C7F9"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'smooth'
                },
                grid: {
                    show: false
                },
                tooltip: {
                    x: {
                        show: false,
                    },
                    y: {
                        show: false,
                    },
                    z: {
                        show: false,
                    },
                    marker: {
                        show: false
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: true
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
            },
            usersTrendSeries: [
                {
                    name: "Users Trend",
                    data: []
                }
            ],
            overallOptions: {
                chart: {
                    type: 'bar',
                    height: 260,
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    dropShadow: {
                        enabled: true,
                        top: 8,
                        left: 0,
                        blur: 3,
                        color: '#7064F5',
                        opacity: 0.1
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '25px',
                        borderRadius: 0,
                    },
                },
                grid: {
                    show: true,
                    borderColor: 'var(--chart-border)',
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 2,
                    dashArray: 0,
                    lineCap: 'butt',
                    colors: "#fff",
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false
                },
                states: {
                    hover: {
                        filter: {
                            type: 'darken',
                            value: 1,
                        }
                    }
                },
                colors: ['#08B258', '#d2efe0'],
                yaxis: {
                    tickAmount: 3,
                    labels: {
                        show: true,
                        style: {
                            fontFamily: 'Rubik, sans-serif',
                        },
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: [],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                responsive: [{
                    breakpoint: 1920,
                    options: {
                        chart: {
                            height: 260,
                        }
                    }
                },
                ]
            },
            overallSeries: [{
                name: 'Installed',
                data: []
            },
            {
                name: 'Activated',
                data: []
            }
            ],
            termiteTreatmentSeries: [],
            termiteTreatmentOptions: {
              chart: {
                type: 'pie',
              },
              labels: ['Resolved', 'Unresolved'],
              colors: ["#0dcaf0", "#ffc107"],
            },
            leakageTreatmentSeries: [],
            leakageTreatmentOptions: {
                chart: {
                    // width: '300px',
                    width: 280,
                    type: 'pie',
                },
                labels: ['Resolved', 'Unresolved'],
                // responsive: [
                //   {
                //       breakpoint: 1600,
                //       options: {
                //           chart: {
                //               width: '320px'
                //           },
                //       }
                //   },
                //     {
                //       breakpoint: 480,
                //       options: {
                //           chart: {
                //               width: 200
                //           },
                //           legend: {
                //               position: 'bottom'
                //           }
                //       }
                //   }
                // ],
                colors: ["#0d6efd", "#f73164"],
            },
            serviceList: [],
        }
    },
    mounted() {
        this.getCountry();
        this.getState();
        this.getAllData();
    },
    components: { Pagination },
    methods: {

        getCountry() {
            const api = `${process.env.VUE_APP_PATH}/global/countries`;
            this.axios.get(api, { params: { type: 'global' } })
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.options.country = res.data.countries;
                    } else {
                        const msg = checkStatusCode(res.data.status_code);
                        this.$toastError(msg);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getState() {
            const api = `${process.env.VUE_APP_PATH}/global/states`;
            this.axios.get(api, { params: { type: 'global', country: this.selected.country } })
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.options.state = res.data.states;
                    } else {
                        const msg = checkStatusCode(res.data.status_code);
                        this.$toastError(msg);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getAllData() {
            this.getCountInfo();
            this.getOverall();
            this.getFirmware();
            this.getReportTreatment();
            this.getUsersTrend();
            this.getList();
        },
        getCountInfo() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/count_info`;
            this.axios.post(api, {
                country: this.selected.country,
                state: this.selected.state,
            })
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.countInfo = res.data;
                        this.termiteCountOptions.xaxis.categories = Object.keys(res.data.termite.summary);
                        this.termiteCountSeries[0].data = Object.values(res.data.termite.summary);
                        this.leakageCountOptions.xaxis.categories = Object.keys(res.data.leakage.summary);
                        this.leakageCountSeries[0].data = Object.values(res.data.leakage.summary);
                    } else {
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        
        getOverall() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/gateway_overall`;
            this.axios.get(api)
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.overallOptions.xaxis.categories = res.data.installed.summary.map(item => item.name);
                        this.overallSeries[0].data = res.data.installed.summary.map(item => item.total);
                        this.overallSeries[1].data = res.data.activated.summary.map(item => item.total);
                        this.overallCount.installed = res.data.installed.total;
                        this.overallCount.activated = res.data.activated.total;
                    } else {
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getFirmware() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/firmware_history`;
            this.axios.get(api)
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.firmwareHistory = res.data.data;
                    } else {   
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getReportTreatment() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/report_status_info`;
            this.axios.post(api, { type: 'TM' })
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.termiteTreatmentSeries = [res.data.solved_count, res.data.unsolved_count];
                    } else {
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });

             this.axios.post(api, { type: 'LK' })
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.leakageTreatmentSeries = [res.data.solved_count, res.data.unsolved_count];
                    } else {
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getCountInfo() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/count_info`;
            this.axios.post(api, {
                country: this.selected.country,
                state: this.selected.state,
            })
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.countInfo = res.data;
                        this.termiteCountOptions.xaxis.categories = Object.keys(res.data.termite.summary);
                        this.termiteCountSeries[0].data = Object.values(res.data.termite.summary);
                        this.leakageCountOptions.xaxis.categories = Object.keys(res.data.leakage.summary);
                        this.leakageCountSeries[0].data = Object.values(res.data.leakage.summary);
                    } else {
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getUsersTrend() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/users_trend`;
            this.axios.get(api)
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.usersTrendOptions.xaxis.categories = res.data.active.summary.map(item => item.name);
                        this.usersTrendSeries[0].data = res.data.active.summary.map(item => item.total);
                    } else {
                        console.log(res.data.status_code);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        getList() {
            const api = `${process.env.VUE_APP_PATH}/dashboard/service_ticket`;
            this.axios.post(api, this.listRequest)
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.serviceList = res.data.data;
                        this.total = res.data.total;
                    } else {
                        const msg = checkStatusCode(res.data.status_code);
                        console.log(msg);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        
        selectChange(val, field) {
            console.log(val, field);
            if (field === 'country' && val) {
                this.selected.state = '';
                this.options.state = [];
                this.getState();
                this.getAllData();
            } else if (field === 'state' && val) {
                this.getAllData();
            }
        },
        sortList(column) {
            if (this.listRequest.order_by === column) {
                this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
            } else {
                this.listRequest.order_by = column;
                this.listRequest.order = 'asc';
            }
            this.getList();
        },
        isActiveSort(column) {
            return this.listRequest.order_by === column;
        },
        updatePage(page) {
            this.listRequest.page = page;
            this.getList();
        },
    }
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 0 !important;
}
.select-wrap {
    width: 100%;
}
.map-gradient {
    background: linear-gradient(to right, var(--logo-green), #499FAF);
}
.light-signal {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    &.R {
        background-color: var(--bs-red);
    }
    &.Y {
        background-color: var(--bs-yellow);
    }
    &.G {
        background-color: var(--logo-green);
    }
    &.B {
        background-color: var(--bs-blue);
    }
}
.count-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    clip-path: inset(0 20px 0 0);
    & .inner-icon {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    &.r {
        background: linear-gradient(to bottom right, rgba(247, 49, 100, 0.2), #fff);
    }
    &.g {
        background: linear-gradient(to bottom right, rgba(8, 178, 88, 0.2), #fff);
    }
    &.y {
        background: linear-gradient(to bottom right, rgba(255, 193, 7, 0.2), #fff);
    }
    &.b {
        background: linear-gradient(to bottom right, rgba(13, 110, 253, 0.2), #fff);
    }
}
.data-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    &.bg-installed {
        background-color: #d2ede0;
    }
    &.bg-activated {
        background-color: var(--logo-green);
    }
  }
.overall-card {
  min-width: 205px;
  display: flex;
  padding: 15px;
  border-radius: 5px;
  gap: 15px;
}
// .light-card-wrap {
//     width: 205px;
// }
.svg-box {
    width: 43px;
    height: 43px;
    background: #fff;
    box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.firmware-overflow {
    overflow: auto;
    max-height: 290px;
}
.firmware-icon {
  width: 35px;
  height: 35px;
  background-color: var(--logo-green);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
