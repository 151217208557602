<template>
  <Breadcrumbs title="Device Management" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex gap-3">
            <div class="select-wrap">
              <multiselect v-model="selected.status" :options="options.status" :searchable="false"
                :close-on-select="true" :show-labels="false" placeholder="Status">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-center search-box">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="showModal('import')">+ Import From File</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'gateway' ? 'txt-primary active' : '']" @click.prevent="changeTab('gateway')">
            Gateway
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" :class="[listRequest.type === 'sensor' ? 'txt-primary active' : '']" @click.prevent="changeTab('sensor')">
            Sensor
          </a>
        </li>
      </ul>

      <div v-if="data.length > 0" class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Name</th>
              <th scope="col">Series Number</th>
              <th scope="col">House Id</th>
              <th scope="col">Group</th>
              <th scope="col">Detection</th>
              <th scope="col">Status</th>
              <th scope="col" @click="sortList('install_at')" class="cursor-p">
                <div class="d-flex justify-content-between align-items-center gap-2">
                  <p class="mb-0">Install at</p>
                  <div>
                    <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('install_at') && listRequest.order === 'asc' }"></i>
                    <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('install_at') && listRequest.order === 'desc' }"></i>
                  </div>
                </div>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
               <td>{{ item.series_number }}</td>
              <td>{{ item.hid }}</td>
                <td>{{ item.group_id }}</td>
                <td :class="{
                  'txt-primary': item.detection === 'good',
                  'text-warning': item.detection === 'risk',
                  'txt-secondary': item.detection === 'critical'
                }">
                  {{ item.detection === 'good' ? 'All Good' : 
                     item.detection === 'risk' ? 'At Risk' : 
                     item.detection === 'critical' ? 'Critical' : '' }}
              </td>
              <td>
                <div class="d-flex gap-2 align-items-center">
                      <label :class="['form-check-label', 'mb-0', { 'txt-primary': item.status }]">{{ item.status ? 'Enabled' : 'Disabled' }}</label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :true-value="1" :false-value="0" @change="switchStaffStatus(item)">
                    </div>
                  </div>
              </td>
              <td>{{ item.install_at }}</td>
              <td>
              <div>
                <router-link v-if="listRequest.type === 'gateway' && item.house_id" :to="{ name: 'houseGateway', params: { houseId: item.house_id, gatewayId: item.id } }" class="link-primary">Detail</router-link>
                <router-link v-else-if="listRequest.type === 'sensor' && item.house_id" :to="{ name: 'houseSensor', params: { houseId: item.house_id, sensorId: item.id } }" class="link-primary">Detail</router-link>
              </div>
            </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="text-center">
        <p>No data</p>
      </div>
    </div>
    <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
  </div>
  <Import ref="import" v-if="shown.import" @hide="hideModal('import')" @update="getList" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import Import from '@/components-gc/modal/deviceManagement/import.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import _ from 'lodash'; 

export default {
  data() {
    return {
      options: {
        status: ['enabled', 'disabled'],
      },
      selected: {
        status: '',
      },
      modals: {},
      shown: {
        import: false,
      },
      data: [],
      listRequest: {
        type: "gateway",
        status: "",
        search: "",
        order_by: "",
        order: "",
        page: 1,
        limit: 10,
      },
      total: 0,
    };
  },
  components: {
    Import,
    Pagination
  },
  mounted() {
    this.getList();
  },
  methods: {
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    changeTab(tab) {
      this.listRequest.type = tab;
      this.getList();
    },
    getList() {
      const api = `${process.env.VUE_APP_PATH}/device/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.data = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    handleSearch() {
      this.listRequest.status = this.selected.status === 'enabled' ? 'Y' : 'N';
      this.getList();
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    switchStaffStatus: _.debounce(function(item) {
      const api = `${process.env.VUE_APP_PATH}/device/switch`;
      this.axios.post(api, {
        type: this.listRequest.type,
        id: item.id,
        enable: item.status === 1
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    }, 300, { leading: true, trailing: false }),
  },
   watch: {
     'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.getList();
      }
    }
  },
}
</script>

<style scoped>
</style>