<template>
    <div id="sidebar-menu">
        <ul class="sidebar-links custom-scrollbar" id="myDIV"
            :style="[layoutobject.split(' ').includes('horizontal-wrapper') ? layout.settings.layout_type == 'rtl' ? { 'margin-right': margin + 'px' } : { 'margin-left': margin + 'px' } : { margin: '0px' }]">
            <li class="back-btn">
                <router-link to="/">
                    <img class="img-fluid" src="../../assets/images/logo/logo-icon.png" alt="" />
                </router-link>
                <div class="mobile-back text-end">
                    <span>Back</span><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                </div>
            </li>
            <li v-for="(menuItem, index) in finalMenus" :key="index" class="sidebar-list"
                :class="{ 'sidebar-main-title': menuItem.type == 'headtitle' }">
                <div v-if="menuItem.type == 'headtitle'">
                    <h6 class="lan-1">{{ menuItem.headTitle1 }}</h6>
                </div>
                <label :class="'badge badge-' + menuItem.badgeType" v-if="menuItem.badgeType">{{ menuItem.badgeValue }}</label>
                <a href="javascript:void(0)" class="sidebar-link sidebar-title" :class="{ 'active': isActive(menuItem) }"
                    v-if="menuItem.type == 'sub' && menuItem.enabled" @click="toggleSubMenu">
                    <svg class="stroke-icon">
                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${menuItem.icon}`"></use>
                    </svg>
                    <svg class="fill-icon">
                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${menuItem.iconf}`"></use>
                    </svg>
                    <span class="lan-3">{{ menuItem.title }}</span>
                    <div class="according-menu" v-if="menuItem.children">
                        <i class="fa fa-angle-right pull-right"></i>
                    </div>
                </a>
                <router-link :to="menuItem.path" v-if="menuItem.type == 'link'" class="sidebar-link sidebar-title" :class="{ 'active': isActive(menuItem) }" @click="hidesecondmenu(); ">
                    <svg class="stroke-icon">
                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${menuItem.icon}`"></use>
                    </svg>
                    <svg class="fill-icon">
                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${menuItem.iconf}`"></use>
                    </svg>
                    <span>{{ menuItem.title }}</span>
                    <i class="fa fa-angle-right pull-right" v-if="menuItem.children"></i>
                </router-link>
                <ul class="sidebar-submenu" v-if="menuItem.children && menuItem.children.length > 0" :class="{ 'menu-open': showSub }"
                    :style="{ display: showSub ? '' : 'none' }">
                    <li v-for="(childrenItem, index) in menuItem.children" :key="index">
                        <router-link class="lan-4" :class="{ 'active': isActive(childrenItem, 'child') }" :to="childrenItem.path"
                            v-if="childrenItem.type == 'link' && childrenItem.enabled" @click="hidesecondmenu(); ">
                            {{ childrenItem.title }}
                            <label :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                                v-if="childrenItem.badgeType">{{ childrenItem.badgeValue }}</label>
                            <i class="fa fa-angle-right pull-right mt-1" v-if="childrenItem.children"></i>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { layoutClasses } from '../../constants/layout';
import axios from 'axios';

export default {
    name: 'Navmenu',
    data() {
        return {
            layoutobj: {},
            items: [],
            text: '',
            active: false,
            menus: [],
            finalMenus: [],
            showSub: false
        };
    },
    mounted() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.accessToken}`;
        axios.defaults.headers.common['apiToken'] = process.env.VUE_APP_API_TOKEN;
        this.getMemberInfo();
        this.getMenus();
        this.$nextTick(() => {
            const elmnt = document.getElementById('myDIV');
            if (elmnt) {
                this.$store.state.menu.menuWidth = elmnt.offsetWidth;
                this.$store.state.menu.menuWidth > window.innerWidth ?
                    (this.$store.state.menu.hideRightArrow = false, this.$store.state.menu.hideLeftArrowRTL = false) :
                    (this.$store.state.menu.hideRightArrow = false, this.$store.state.menu.hideLeftArrowRTL = true);
            }
        });
    },
    computed: {
        ...mapState('user', ['accessToken']),
        ...mapState({
            menuItems: state => state.menu.data, //menu.js
            layout: state => state.layout.layout,
            sidebar: state => state.layout.sidebarType,
            activeoverlay: state => state.menu.activeoverlay,
            togglesidebar: state => state.menu.togglesidebar,
            width: state => state.menu.width,
            height: state => state.menu.height,
            margin: state => state.menu.margin,
            menuWidth: state => state.menu.menuWidth,
        }),
        layoutobject: {
            get() {
                return JSON.parse(JSON.stringify(layoutClasses.find(item => Object.keys(item).pop() === this.layout.settings.layout)))[this.layout.settings.layout];
            },
            set() {
                this.layoutobj = layoutClasses.find(item => Object.keys(item).pop() === this.layout.settings.layout);
                this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];
                return this.layoutobj;
            }
        },
    },
    watch: {
        width() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            window.addEventListener('scroll', this.handleScroll);
            this.handleScroll();
            if (window.innerWidth < 992) {
                const newlayout = JSON.parse(JSON.stringify(this.layoutobject).replace('horizontal-wrapper', 'compact-wrapper'));
                document.querySelector('.page-wrapper').className = 'page-wrapper ' + newlayout;
                this.$store.state.menu.margin = 0;
            } else {
                document.querySelector('.page-wrapper').className = 'page-wrapper ' + this.layoutobject;
            }

            if ((window.innerWidth < 1199 && ['Tokyo', 'Moscow', 'Rome'].includes(this.layout.settings.layout))) {
                this.finalMenus.forEach(menuItem => menuItem.active = false);
            }
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        if (this.$store.state.menu.width < 991) {
            this.layout.settings.layout = 'Dubai';
            this.margin = 0;
        }
        // setTimeout(() => {
        //     const elmnt = document.getElementById('myDIV');
        //     this.$store.state.menu.menuWidth = elmnt.offsetWidth;
        //     this.$store.state.menu.menuWidth > window.innerWidth ?
        //         (this.$store.state.menu.hideRightArrow = false, this.$store.state.menu.hideLeftArrowRTL = false) :
        //         (this.$store.state.menu.hideRightArrow = false, this.$store.state.menu.hideLeftArrowRTL = true);
        // }, 500);
        this.layoutobject = layoutClasses.find(item => Object.keys(item).pop() === this.layout.settings.layout);
        this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[this.layout.settings.layout];
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
            ...mapMutations('user', ['setMemberInfo']),

        getMenus() {
            const api = `${process.env.VUE_APP_PATH}/member/permissions`;
            this.axios.get(api)
                .then((res) => {
                    if (res.data.status_code === 'SYSTEM_1000') {
                        this.menus = res.data.permissions;
                        this.checkMenus();
                        this.checkSubMenus();
                    } else {
                        console.log(res);
                    }
                }).catch(error => {
                    console.log('Error:', error);
                });
        },
        checkMenus() {
            this.finalMenus = this.menuItems.map(menuItem => {
                const matchedMenu = this.menus.find(menu => 
                    menu.name && menuItem.title && menu.name.toLowerCase() === menuItem.title.toLowerCase()
                );
                if (matchedMenu) {
                    return {
                        ...menuItem,
                        id: matchedMenu.id,
                        enabled: matchedMenu.enabled,
                        children: menuItem.children ? menuItem.children.map(childItem => {
                            const matchedChild = matchedMenu.children.find(child => 
                                child.name && childItem.title && child.name.toLowerCase() === childItem.title.toLowerCase()
                            );
                            return matchedChild ? {
                                ...childItem,
                                id: matchedChild.id,
                                enabled: matchedChild.enabled
                            } : childItem;
                        }) : menuItem.children 
                    };
                }
                return menuItem;
            });
        },
        checkSubMenus() {
            this.finalMenus.forEach(menuItem => {
                if (menuItem.type == 'sub') {
                    menuItem.children.forEach(child => {
                        if(this.isActive(child, 'child')) {
                            this.showSub = true;
                        }
                    });
                }
            });
        },
        HandleUnload() {
            let pinsArray = [];
            this.finalMenus.forEach(item => {
                if (item.showPin) pinsArray.push(item.title);
            });
            localStorage.setItem('pins', JSON.stringify(pinsArray));
        },
        handleScroll() {
            if (window.scrollY > 400) {
                if (['material-type', 'advance-layout'].includes(this.layoutobject.split(' ').pop())) {
                    document.querySelector('.sidebar-main').className = 'sidebar-main hovered';
                }
            } else {
                if (document.getElementById('sidebar-main')) {
                    document.querySelector('.sidebar-main').className = 'sidebar-main';
                }
            }
        },
        hidesecondmenu() {
            if (window.innerWidth < 991 || this.layoutobject.split(' ').includes('compact-sidebar')) {
                this.$store.state.menu.activeoverlay = false;
                this.$store.state.menu.togglesidebar = false;
                this.finalMenus.forEach(menuItem => menuItem.active = false);
            }
        },
        handleResize() {
            this.$store.state.menu.width = window.innerWidth - 450;
        },
        isActive(menuItem, child = null) {
            if(child === 'child') {
                const basePath = menuItem.path.split('/')[2]; 
                if (basePath) {
                    return this.$route.path.includes(basePath);
                }
            } else {
                if(menuItem.path === '/' && this.$route.path === '/') return true;

                if (menuItem.type == 'link') {
                    const basePath = menuItem.path.split('/')[1]; 
                    if (basePath) {
                        return this.$route.path.includes(basePath);
                    }
                }
                
                if (menuItem.type == 'sub') {
                    let isActive = false
                    menuItem.children.forEach(child => {
                        if(this.isActive(child, 'child')) {
                            isActive = true
                        }
                    });
                    return isActive
                }
                return false;
            }
        },
        toggleSubMenu() {
            this.showSub = !this.showSub;
        },
        getMemberInfo() {
      const api = `${process.env.VUE_APP_PATH}/member/info`;
      this.axios.get(api)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.setMemberInfo(res.data.member);
            } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    },
};
</script>