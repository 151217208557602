<template>
  <div class="card map-sidebarh">
    <div class="card-header d-flex justify-content-between align-items-center gap-2 flex-wrap border-bottom-0 text-secondary">
      <div class="d-flex align-items-center gap-1">
        <router-link :to="getBackRoute()" class="link-dark">
          <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
        </router-link>
        <p class="fs-5 mb-0">{{ gatewayInfo.name }}</p>
      </div>
      <p class="fs-small mb-0">Last Update: {{ gatewayInfo.last_update }}</p>
    </div>
    <div class="card-body h-100">
      <div v-if="gatewayInfo.house" class="mb-3">
        <h6>{{ gatewayInfo.house.name }}</h6>
        <p class="mb-0 text-secondary">{{ gatewayInfo.house.address }}</p>
      </div>
      <div v-if="gatewayInfo.house" class="mb-3">
        <h6>House ID</h6>
        <p class="mb-0 text-secondary">#{{ gatewayInfo.house.id }}</p>
      </div>
      <div class="mb-3">
          <multiselect 
          :options="options.group" 
          :searchable="false" 
          :close-on-select="true" 
          :show-labels="false" 
          track-by="val"
          label="name" 
          v-model="selected.group" 
          placeholder="Group"
          :allow-empty="false" 
          @update:modelValue="selectChange"
          >
          </multiselect>
        </div>
      <div class="d-flex align-items-center gap-2 mb-3">
        <div>
          <multiselect 
            v-model="selected.status"
            :options="options.status" 
            :searchable="false" 
            :close-on-select="true" 
            :show-labels="false" 
            placeholder="Status"
            track-by="val"
            label="name" 
            :allow-empty="false" 
            >
          </multiselect>
        </div>
        <div class="flex-grow-1">
          <multiselect 
          :options="options.condition" 
          :searchable="false" 
          :close-on-select="true" 
          :show-labels="false" 
          track-by="val"
          label="name" 
          v-model="selected.condition" 
          placeholder="Condition"
          :allow-empty="false" 
          >
          </multiselect>
        </div>
        <div class="cursor-p" @click.prevent="refresh">
          <vue-feather type="refresh-cw" size="20"></vue-feather>
        </div>
      </div>
      <div v-if="gatewayInfo.device_version_list && gatewayInfo.device_version_list.length > 0" class="fs-sm text-secondary mb-3">
        <p class="mb-0">Firmware Version: {{ gatewayInfo.device_version_list.find(item => item.name === 'gfv').version }}</p>
        <p class="mb-0">Bootloader Firmware Version: {{ gatewayInfo.device_version_list.find(item => item.name === 'gbfv').version }}</p>
        <p class="mb-0">Nisus Firmware Version: {{ gatewayInfo.device_version_list.find(item => item.name === 'kfv').version }}</p>
        <p class="mb-0">Wi-Fi/BT Firmware Version: {{ gatewayInfo.device_version_list.find(item => item.name === 'wfv').version }}</p>
        <p class="mb-0">node Firmware Version: {{ gatewayInfo.device_version_list.find(item => item.name === 'nfv').version }}</p>
        <p class="mb-4">node Bootloader Firmware Version: {{ gatewayInfo.device_version_list.find(item => item.name === 'nfbv').version }}</p>
        <p class="mb-0">Last successful check for update</p>
        <p class="mb-0">{{ gatewayInfo.last_check_update_time }}</p>
      </div>
      <div class="btns-wrap d-flex flex-column justify-content-end gap-2">
         <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2" @click.prevent="showModal('resetModal')">
          <vue-feather type="repeat" size="18" class=""></vue-feather>
          <p class="mb-0">Reset</p>
        </button>
        <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2" @click.prevent="showModal('replaceModal')">
          <vue-feather type="repeat" size="18" class=""></vue-feather>
          <p class="mb-0">Replace</p>
        </button>
        <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2" @click.prevent="showModal('deleteModal')">
          <vue-feather type="trash-2" size="18" class=""></vue-feather>
          <p class="mb-0">Delete</p>
        </button>
      </div>
    </div>
  </div>
  <resetModal v-if="shown.resetModal" ref="resetModal" :type="'gateway'"
    @mqttReset="(resetType) => mqttReset(resetType)"/>
  <replaceModal v-if="shown.replaceModal" ref="replaceModal" :type="'gateway'"
    @mqttReplace="(newGid) => mqttUpdate(newGid)" @hide="hideModal('replaceModal')"/>
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" 
  :type="'gateway'" :id="$route.params.gatewayId" @update="getGateInfo" @hide="hideModal('deleteModal')"/>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import resetModal from '@/components-gc/modal/reset.vue'
import replaceModal from '@/components-gc/modal/replace.vue'
import deleteModal from '@/components-gc/modal/delete.vue'
import { checkStatusCode } from '@/methods-gc/statusCode';
import mqtt from "mqtt";  
import _ from 'lodash'; 

export default {
  data() {
    return {
      gatewayInfo: {},
      options: {
        status: [
          { val: 0, name: 'Disable' },
          { val: 1, name: 'Enable' }
        ],
        condition: [
          { val: 'auto', name: 'Interval detection: auto', seconds: 0 },
          { val: 'halfDay', name: 'Interval detection: halfDay', seconds: 43200 },
          { val: 'oneDay', name: 'Interval detection: oneDay', seconds: 86400 },
          { val: 'oneHalfDay', name: 'Interval detection: oneHalfDay', seconds: 129600 },
          { val: 'twoDays', name: 'Interval detection: twoDays', seconds: 172800 },
          { val: 'threeDays', name: 'Interval detection: threeDays', seconds: 259200 },
          { val: 'fourDays', name: 'Interval detection: fourDays', seconds: 345600 },
          { val: 'fiveDays', name: 'Interval detection: fiveDays', seconds: 432000 },
          { val: 'sixDays', name: 'Interval detection: sixDays', seconds: 518400 },
          { val: 'sevenDays', name: 'Interval detection: sevenDays', seconds: 604800 },
        ],
        group: [
          { val: 1, name: 'Group 1' },
          { val: 2, name: 'Group 2' },
          { val: 3, name: 'Group 3' },
          { val: 4, name: 'Group 4' },
          { val: 5, name: 'Group 5' },
          { val: 6, name: 'Group 6' },
          { val: 7, name: 'Group 7' },
          { val: 8, name: 'Group 8' },
          { val: 9, name: 'Group 9' },
          { val: 10, name: 'Group 10' },
        ],
      },
      selected: {
        status: null,
        condition: null,
        group: null,
      },
      mqttConfig: {
        broker: process.env.VUE_APP_MQTT_BROKER,
        clientId: this.$route.params.gatewayId,
        username: process.env.VUE_APP_MQTT_USERNAME,
        password: process.env.VUE_APP_MQTT_PASSWORD,
        gid: null,
      },
      retryWait: 1000,
      maxRetries: 3,
      isSettingInterval: false, 
      isSettingUpdate: false,
      isSettingReset: false,
      newGid: null,
      resetType: null,
      modals: {},
      shown: {
        resetModal: false,
        replaceModal: false,
        deleteModal: false,
      },
    }
  },
  components: {
    resetModal, replaceModal, deleteModal
  },
  mounted() {
    this.getGateInfo();
  },
  methods: {
    getGateInfo() {
      const api = `${process.env.VUE_APP_PATH}/house/device/gateway_info`;
      this.axios.post(api, {
        house_id: this.$route.params.houseId,
        gateway_id: this.$route.params.gatewayId
      })
      .then((res) => {
        const msg = checkStatusCode(res.data.status_code);
        if (res.data.status_code === 'SYSTEM_1000') {
          this.gatewayInfo = res.data.gateway;
          this.selected.group = this.options.group.find(item => item.val === this.gatewayInfo.group_id);
          this.selected.condition = this.options.condition.find(item => item.val === this.gatewayInfo.interval);
          this.selected.status = this.options.status.find(item => item.val === this.gatewayInfo.enable);
          this.mqttConfig.gid = this.gatewayInfo.gid; 
        } else if(res.data.status_code === 'SYSTEM_0006') {
          this.$toastWarning(`${msg} You will be redirected to the list.`);
          setTimeout(() => {
            this.$router.push(this.getBackRoute());
          }, 4000);
        } else {
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    refresh() {
      if (this.selected.condition && this.selected.condition.val !== this.gatewayInfo.interval) {
        this.setIntervalD();
      }
      if (this.selected.status && this.selected.status.val !== this.gatewayInfo.enable) {
        this.switchGateway();
      }
    },
    switchGateway() {
      const loadingId = this.$toastLoading('Processing, please wait...');
      const api = `${process.env.VUE_APP_PATH}/house/device/gateway/switch`;
      this.axios.post(api, {
        gateway_id: this.$route.params.gatewayId,
        enable: this.selected.status.val
      })
      .then((res) => {
        const msg = checkStatusCode(res.data.status_code);
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastUpdate(loadingId, 'Switch successfully!', {
            type: 'success',
          });
          this.getGateInfo();
        } else {
          this.$toastUpdate(loadingId, msg, {
          type: 'error',
        });
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    setIntervalD: _.debounce(async function () {
      if (this.isSettingInterval) return;
      this.isSettingInterval = true;

      const loadingId = this.$toastLoading('Processing, please wait...');
      console.log('loadingId', loadingId);

      const topicAP = `nk/tm/${this.mqttConfig.gid}/toAP`;
      const topicGW = `nk/tm/${this.mqttConfig.gid}/toGW`;
      const { broker, clientId, username, password } = this.mqttConfig;
      const client = mqtt.connect(broker, { clientId, username, password });
      let receivedOk = false;
      let retryCount = 0;
      const self = this;

      client.on("connect", setInterval);

      async function setInterval() {
        if (retryCount >= 3) {
          console.log('Max retries reached, stopping resends.');
          self.$toastUpdate(loadingId, 'Failed to set interval.', {
            type: 'error',
          });
          self.isSettingInterval = false;
          if (client.connected) client.end();
          return;
        }

        console.log('setInterval Connected to broker', 'retryCount', retryCount, receivedOk)
        client.subscribe(topicAP, async (error) => {
          if (error) {
            console.log("Subscribe error:", error);
            self.$toastUpdate(loadingId, 'Failed to set interval.', {
              type: 'error',
            });
            self.isSettingInterval = false;
          } else {
            console.log("Subscribed successfully");
            await self.publishMsg('setInterval', client, topicGW, { iv: self.selected.condition.seconds }, loadingId);
            receivedOk = await self.receiveMsg('setInterval', client, topicAP, loadingId);

            console.log('receivedOk', receivedOk);
            if (!receivedOk && retryCount < self.maxRetries) {
              console.log('retry', retryCount)
              retryCount++; 
              setTimeout(() => {
                setInterval();
              }, self.retryWait);
            } else if (!receivedOk) {
              console.log('no response', retryCount);
              self.$toastUpdate(loadingId, 'Failed to set interval.', {
                type: 'error',
              });
              self.isSettingInterval = false;
            }
          }
        });
      }

    }, 300, { leading: true, trailing: false }),

    mqttUpdate: _.debounce(async function(gid) {
      if (this.isSettingUpdate) return;
      this.isSettingUpdate = true;
      this.newGid = gid;

      const loadingId = this.$toastLoading('Processing, please wait...');
      const topicAP = `nk/tm/${this.newGid}/toAP`;
      const topicGW = `nk/tm/${this.newGid}/toGW`;
      const { broker, clientId, username, password } = this.mqttConfig;
      const client = mqtt.connect(broker, { clientId, username, password });
      let receivedOk = false;
      let retryCount = 0;
      const self = this;

      client.on("connect", update);

      async function update() {
        if (retryCount >= 3) {
          console.log('Max retries reached, stopping resends.');
          self.$toastUpdate(loadingId, 'Failed to update.', {
            type: 'error',
          });
          self.isSettingUpdate = false;
          if (client.connected) client.end();
          return;
        }

        console.log('update Connected to broker', 'retryCount', retryCount, receivedOk)
        client.subscribe(topicAP, async (error) => {
          if (error) {
            console.log("Subscribe error:", error);
            self.$toastUpdate(loadingId, 'Failed to update.', {
              type: 'error',
            });
            self.isSettingUpdate = false;
          } else {
            console.log("Subscribed successfully");
            await self.publishMsg('update', client, topicGW, { ls: true }, loadingId);
            receivedOk = await self.receiveMsg('update', client, topicAP, loadingId);

            if (!receivedOk && retryCount < self.maxRetries) {
              console.log('retry', retryCount)
              retryCount++; 
              setTimeout(() => {
                update();
              }, self.retryWait);
            } else if (!receivedOk) {
              console.log('no response', retryCount);
              self.$toastUpdate(loadingId, 'Failed to update.', {
                type: 'error',
              });
              self.isSettingUpdate = false;
            }
          }
        });
      }

    }, 300, { leading: true, trailing: false }),

    mqttReset: _.debounce(async function(resetType) {
      if (this.isSettingReset) return;
      this.isSettingReset = true;

      this.resetType = resetType;
      const loadingId = this.$toastLoading('Processing, please wait...');
      console.log('loadingId', loadingId)

      const topicAP = `nk/tm/${this.mqttConfig.gid}/toAP`;
      const topicGW = `nk/tm/${this.mqttConfig.gid}/toGW`;
      const { broker, clientId, username, password } = this.mqttConfig;
      const client = mqtt.connect(broker, { clientId, username, password });
      let receivedOk = false;
      let retryCount = 0; 
      const self = this;

      client.on("connect", reset);

      async function reset() {
        if (retryCount >= 3) {
          console.log('Max retries reached, stopping resends.');
          self.$toastUpdate(loadingId, 'Failed to reset.', {
            type: 'error',
          });
          self.isSettingReset = false;
          if (client.connected) client.end();
          return;
        }

        console.log('reset Connected to broker', 'retryCount', retryCount, receivedOk)
        client.subscribe(topicAP, async (error) => {
          if (error) {
            console.log("Subscribe error:", error);
            self.$toastUpdate(loadingId, 'Failed to reset.', {
              type: 'error',
            });
            self.isSettingReset = false;
          } else {
            console.log("Subscribed successfully");
            await self.publishMsg('reset', client, topicGW, { nid: self.resetType, rs: true }, loadingId);
            receivedOk = await self.receiveMsg('reset', client, topicAP, loadingId);
            console.log('receivedOk', receivedOk, "retryCount",retryCount, 'maxRetries', self.maxRetries);

            if (!receivedOk && retryCount < self.maxRetries) {
              console.log('retry', retryCount)
              retryCount++; 
              setTimeout(() => {
                reset();
              }, self.retryWait);
            } else if (!receivedOk) {
              console.log('no response', retryCount);
              self.$toastUpdate(loadingId, 'Failed to reset.', {
                type: 'error',
              });
              self.isSettingReset = false;
            }
          }
        });
      }
    }, 300, { leading: true, trailing: false }
  ),
    publishMsg(type, client, topicGW, payload, loadingId) {
      return new Promise((resolve, reject) => {
        client.publish(topicGW, JSON.stringify(payload), (error) => {
          if (error) {
            console.log("Publish error:", error);
            this.$toastUpdate(loadingId, `Failed to ${type}.`, {
              type: 'error',
            });
            reject(error);
          } else {
            console.log("Message published successfully");
            resolve();
          }
        });
      });
    },
    receiveMsg(type, client, topicAP, loadingId) {
      let receivedOk = false;
      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          if (!receivedOk) {
            console.log('Timeout reached, no response received.');
            resolve(false);
          }
        }, 1000);

        client.on("message", (topic, message) => {
          const msg = JSON.parse(message.toString());
          console.log('接收消息', "msg", msg);
          if (topic === topicAP) {
            switch(type) {
              case 'reset':
                if (msg.s_rs === 'ok') {
                  this.hideModal('resetModal');
                  this.$toastUpdate(loadingId, 'Reset successfully!', {
                    type: 'success',
                  });
                  receivedOk = true;
                  this.isSettingReset = false;
                }
                break;
              case 'setInterval':
                if (msg.s_iv === 'done') {
                  this.$toastUpdate(loadingId, 'Interval set successfully!', {
                    type: 'success',
                  });
                  receivedOk = true;
                  this.isSettingInterval = false;
                }
                break;
              case 'update':
                if (msg.s_ls === 'ok') {
                  this.hideModal('replaceModal');
                  this.$toastUpdate(loadingId, 'Update successfully!', {
                    type: 'success',
                  });
                  this.getGateInfo();
                  receivedOk = true;
                  this.isSettingUpdate = false;
                }
                break;
              default:
                console.log('Unknown message type');
            }

            if (receivedOk) {
              clearTimeout(timeout);
              if (client.connected) client.end();
              this[`isSetting${this.$capitalizeFirst(type)}`] = false;
              resolve(true);
            } else {
              console.log('No response received, resending message...');
              resolve(false);
            }
          }
        });
      });
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getBackRoute() {
      switch(this.$route.name) {
        case 'userGateway':
          return { name: 'userDeviceList' };
        case 'staffGateway':
          return { name: 'staffDeviceList' };
        case 'houseGateway':
          return { name: 'houseDeviceList' };
        default:
          console.log(this.$route.name);
      }
    },
    selectChange(item) {
      if(item){
        const api = `${process.env.VUE_APP_PATH}/house/device/gateway/group`;
        const loadingId = this.$toastLoading('Processing, please wait...');
        this.axios.post(api, {
          gateway_id: this.$route.params.gatewayId,
          house_id: this.$route.params.houseId, 
          group_id: item.val,
        })
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$toastUpdate(loadingId, 'Updated successfully', {
              type: 'success',
            });
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastUpdate(loadingId, msg, {
              type: 'error',
            });
            this.getGateInfo();
          }
        }).catch(error => {
            console.log('Error:', error);
          });
      }
    } 
  }
}
</script>

<style scoped>
.vue-feather.vue-feather--refresh-cw {
  color: var(--theme-deafult);
}
@media (min-width: 1500px) {
  .btns-wrap {
    flex-direction: row!important;
  }
}
</style>