<template>
  <Breadcrumbs :title="`${owner.first_name} ${owner.last_name}`" main="Staff Management"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-header">
            <div class="d-flex gap-2 justify-content-between align-items-start mb-4">
              <h5 class="f-w-400 text-secondary">#HS{{ $route.params.caseId }}</h5>
              <p class="mb-0 fw-semibold" :class="{
                'text-primary': reportStatus === 'assigned',
                'text-warning': reportStatus === 'pending',
                'txt-primary': reportStatus === 'done'
              }">{{ $capitalizeFirst(reportStatus) }}</p>
            </div>
            <p class="text-secondary fs-6">Home Service Information</p>
            <div>
              <div class="d-flex gap-2 mb-3">
                <img :src="house.image ? house.image : 'https://fakeimg.pl/50x50/?text=50x50'" class="img-50" alt="house">
                <div>
                  <h6>{{ house.name }}</h6>
                  <p class="mb-0 text-secondary fs-sm">{{ house.address }}</p>
                </div>
              </div>
              <div>
                <h6 class="mb-0">House ID</h6>
                <p class="mb-0 text-secondary">#H{{ house.id }}</p>
              </div>
              <div v-for="gateway in house.gateways" :key="gateway.id" class="row g-2 flex-wrap justify-content-between mb-2">
                <div class="col-5 d-flex gap-2 txt-primary align-items-center">
                  <vue-feather type="share-2" fill="#14a1b1"></vue-feather>
                  <div>
                    <p class="fs-sm mb-0">{{ gateway.gateway_name }}</p>
                    <small class="d-block" :class="{
                      'txt-primary': gateway.status === 'good',
                      'text-warning': gateway.status === 'risk',
                      'txt-secondary': gateway.status === 'critical'
                    }">
                       {{ gateway.status === 'good' ? 'All Good' : 
                         gateway.status === 'risk' ? 'At Risk' : 
                         gateway.status === 'critical' ? 'Critical' : '' }}
                    </small>
                  </div>
                </div>
                <div class="col-5">
                  <p class="fs-sm mb-0 text-secondary">{{ gateway.nodes_count }} Devices</p>
                  <div class="d-flex flex-row align-items-center gap-1">
                    <Battery60Icon size="16" :class="{
                      'txt-primary': gateway.battery === 'good',
                      'text-warning': gateway.battery === 'risk',
                      'txt-secondary': gateway.battery === 'critical'
                    }"/>
                    <WifiStrength3Icon size="16" :class="{
                      'txt-primary': gateway.connection === 'good',
                      'text-warning': gateway.connection === 'risk',
                      'txt-secondary': gateway.connection === 'critical'
                    }"/>
                    <ThermometerIcon size="16" :class="{
                      'txt-primary': gateway.temperature === 'good',
                      'text-warning': gateway.temperature === 'risk',
                      'txt-secondary': gateway.temperature === 'critical'
                    }"/>
                    <WaterPercentIcon size="16" :class="{
                      'txt-primary': gateway.moisture === 'good',
                      'text-warning': gateway.moisture === 'risk',
                      'txt-secondary': gateway.moisture === 'critical'
                    }"/>
                    <HomeOutlineIcon size="16" :class="{
                      'txt-primary': gateway.status === 'good',
                      'text-warning': gateway.status === 'risk',
                      'txt-secondary': gateway.status === 'critical'
                    }"/>
                    <small class="ms-2" :class="{
                      'txt-primary': gateway.status === 'good',
                      'text-warning': gateway.status === 'risk',
                      'txt-secondary': gateway.status === 'critical'
                    }">
                      {{ gateway.status === 'good' ? 'All Good' : 
                         gateway.status === 'risk' ? 'At Risk' : 
                         gateway.status === 'critical' ? 'Critical' : '' }}
                    </small>
                  </div>
                </div>
                <h6 class="col-2 mb-0">
                  <router-link :to="{ name: 'staffDeviceList', params: { houseId: house.id } }" 
                  class="badge bg-light align-self-start" :class="{
                    'txt-primary': gateway.status === 'good',
                    'text-warning': gateway.status === 'risk',
                    'txt-secondary': gateway.status === 'critical',
                    'text-dark': gateway.status === ''
                  }">Detail</router-link>
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p class="text-secondary fs-6">Owner Information</p>
            <div class="row">
              <div class="col-md-7 mb-3">
                <div class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="owner.image ? owner.image : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ owner.first_name }} {{ owner.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ owner.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-3">
                <h6>Created at</h6>
                <p class="mb-0 text-secondary">{{ owner.created_at }}</p>
              </div>
              <div class="col-md-7">
                <div class="d-flex align-items-center gap-2">
                  <div class="img-40"></div>
                  <div>
                    <h6>Mobile</h6>
                    <p class="mb-0 text-secondary">{{ owner.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <h6>Local Phone</h6>
                <p class="mb-0 text-secondary">{{ owner.local_phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="f-w-400 text-secondary">Report Details</h5>
          </div>
          <div class="card-body d-flex justify-content-between align-items-center"
          v-for="(sensor, index) in reportDetail.sensers" :key="sensor.id"
          :class="{ 'border-top-0': index === 0 }">
            <div class="">
              <p class="fs-sm mb-0 text-secondary">{{ sensor.name }}</p>
              <div class="d-flex flex-row align-items-center gap-1">
                <Battery60Icon size="16" :class="{
                  'txt-primary': sensor.battery.status === 'good',
                  'text-warning': sensor.battery.status === 'risk',
                  'txt-secondary': sensor.battery.status === 'critical'
                }"/>
                <WifiStrengthIcon size="16" :class="{
                  'txt-primary': sensor.connection.status === 'good',
                  'text-warning': sensor.connection.status === 'risk',
                  'txt-secondary': sensor.connection.status === 'critical'
                }"/>
                <ThermometerIcon size="16" :class="{
                  'txt-primary': sensor.temperature.status === 'good',
                  'text-warning': sensor.temperature.status === 'risk',
                  'txt-secondary': sensor.temperature.status === 'critical'
                }"/>
                <WaterPercentIcon size="16" :class="{
                  'txt-primary': sensor.moisture.status === 'good',
                  'text-warning': sensor.moisture.status === 'risk',
                  'txt-secondary': sensor.moisture.status === 'critical'
                }"/>
                <HomeOutlineIcon size="16" :class="{
                  'txt-primary': sensor.status === 'good',
                  'text-warning': sensor.status === 'risk',
                  'txt-secondary': sensor.status === 'critical'
                }"/>
                <small class="ms-2" :class="{
                  'txt-primary': sensor.status === 'good',
                  'text-warning': sensor.status === 'risk',
                  'txt-secondary': sensor.status === 'critical'
                }">
                   {{ sensor.status === 'good' ? 'All Good' : 
                         sensor.status === 'risk' ? 'At Risk' : 
                         sensor.status === 'critical' ? 'Critical' : '' }}
                </small>
              </div>
            </div>
            <!-- <h6 class="mb-0">
              <router-link :to="{ name: 'staffSensor', params: { houseId: house.id, sensorId: sensor.id } }" 
              class="badge bg-light align-self-start" :class="{
                'txt-primary': sensor.status === 'good',
                'text-warning': sensor.status === 'risk',
                'txt-secondary': sensor.status === 'critical',
                'text-dark': sensor.status === ''
              }">Detail</router-link>
            </h6> -->
          </div>
          <div class="card-body border-top-0">
            <div class="row">
              <div class="col-md-6">
                <p class="mb-0">Summary</p>
                <p class="mb-0 fs-sm">
                  <span class="txt-secondary" v-for="(summary, index) in reportDetail.summary" :key="summary.status + index">
                    {{ summary.count }} Devices {{ summary.status }}</span>
                </p>
              </div>
              <div class="col-md-6">
                <p class="mb-0 fw-semibold">Report at</p>
                <p class="mb-0 fs-sm text-secondary">{{ reportDetail.report_at }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="card">
          <div class="card-header">
            <p class="fs-5 text-secondary mb-0">Report History</p>
          </div>
          <div class="report-history-wrap">
            <div class="card-body" v-for="(history, index) in reportHistory" :key="history.title + index" 
            :class="{ 'border-bottom': index !== reportHistory.length - 1 }">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h6 class="mb-0">{{ history.title }}</h6>
                <p class="fs-sm text-secondary">{{ history.date }}</p>
              </div>
              <p class="text-secondary mb-4">{{ history.content }}</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="history.staff.profile ? history.staff.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ history.staff.first_name }} {{ history.staff.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ history.staff.email }}</p>
                  </div>
                </div>
                <h5 :class="{
                  'txt-primary': history.status === 'done',
                  'text-warning': history.status === 'pending'
                }">Service Result : {{ history.status === 'done' ? 'Done' : 'Pending' }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      reportStatus: '',
      house: {},
      owner: {},
      reportDetail: {},
      reportHistory: []
    }
  },
  methods: {
    getReportData() {
      const api = `${process.env.VUE_APP_PATH}/staff/report_history`;
      this.axios.post(api, {
        report_id: this.$route.params.caseId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.reportStatus = res.data.report_status;
          this.house = res.data.house;
          this.owner = res.data.owner;
          this.reportDetail = res.data.report_detail;
          this.reportHistory = res.data.report_history;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          console.log(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  mounted() {
    this.getReportData();
  }
}
</script>

<style scoped>
.report-history-wrap {
  overflow: auto;
  /* max-height: calc(100vh - 200px); */
}
</style>