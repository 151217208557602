export function checkStatusCode(code) {
  let msg = '';
  switch(code) {
    case 'SYSTEM_1000':
      msg = 'Operation successful.'; // 操作成功。
      break;
    case 'SYSTEM_0001':
      msg = 'Verification failed.'; // 驗證失敗。
      break;
    case 'SYSTEM_0002':
      msg = 'No user information.'; // 無使用者資訊。
      break;
    case 'SYSTEM_0003':
      msg = 'No employee information.'; // 無員工資訊。
      break;
    case 'SYSTEM_0004':
      msg = 'No house information.'; // 無房子資訊。
      break;
    case 'SYSTEM_0005':
      msg = 'No report information.'; // 無Report資訊。
      break;
    case 'SYSTEM_0006':
      msg = 'No gateway information.'; // 無Gateway資訊。
      break;
    case 'SYSTEM_0007':
      msg = 'No sensor information.'; // 無Sensor資訊。
      break;
    case 'SYSTEM_0008':
      msg = 'No terms of service information.'; // 無TermsOfService資訊。
      break;
    case 'SYSTEM_0009':
      msg = 'No member criteria information.'; // 無MemberCriteria資訊。
      break;
    case 'SYSTEM_0010':
      msg = 'No notification information.'; // 無通知資訊。
      break;
    case 'SYSTEM_0011':
      msg = 'No administrator information.'; // 無管理員資訊。
      break;
    case 'SYSTEM_0012':
      msg = 'No role information.'; // 無role資訊。
      break;
    case 'SYSTEM_0013':
      msg = 'No update information.'; // 無更新資訊。
      break;
    case 'SYSTEM_0014':
      msg = 'Firmware file parsing error.'; // 更新韌體檔解析錯誤。
      break;
    case 'SYSTEM_0015':
      msg = 'Firmware file name already exists.'; // 更新韌體檔檔名已存在。
      break;
    case 'SYSTEM_0016':
      msg = 'No Information information.'; // 無Information資訊。
      break;
    case 'SYSTEM_0017':
      msg = 'No About us information.'; // 無About us資訊。
      break;
    case 'SYSTEM_0018':
      msg = 'No Contact to Service information.'; // 無Contact to Service資訊。
      break;
    case 'SYSTEM_0019':
      msg = 'No FAQ information.'; // 無FAQ資訊。
      break;
    case 'SYSTEM_0020':
      msg = 'Gateway has set house.'; // Gateway已設定房屋。
      break;
    case 'SYSTEM_0021':
      msg = 'Gateway has not set house.'; // Gateway未設定房屋。
      break;
    case 'SYSTEM_0022':
      msg = 'Sensor has set data.'; // Sensor已設定資料。
      break;
    case 'SYSTEM_0023':
      msg = 'Sensor has not set data.'; // Sensor未設定資料。
      break;
    case 'SYSTEM_0024':
      msg = 'No account deletion request information.'; // 無申請刪除帳號資訊。
      break;
    case 'SYSTEM_2001':
      msg = 'No user information.'; // 無用戶資訊。
      break;
    case 'SYSTEM_2002':
      msg = 'Incorrect account or password.'; // 帳號或密碼錯誤。
      break;
    case 'SYSTEM_2003':
      msg = 'Account role has been disabled.'; // 帳號Role已停用。
      break;
    case 'SYSTEM_2004':
      msg = 'Account has been disabled.'; // 帳號已停用。
      break;
    case 'SYSTEM_2005':
      msg = 'No login permission.'; // 無登入權限。
      break;
    case 'SYSTEM_3001':
      msg = 'Data duplication.'; // 資料重複。
      break;
    case 'SYSTEM_3002':
      msg = 'Item has been published, operation not allowed.'; // 項目已發佈，無法操作。
      break;
    case 'SYSTEM_4000':
      // msg = 'No access rights.'; // 無使用權限。
      msg = null
      break;
    case 'SYSTEM_4001':
      msg = 'User role has been disabled.'; // 使用者Role已停用。
      break;
    case 'SYSTEM_5000':
      msg = 'Data import failed, file format error or data duplication.'; // 資料匯入失敗，匯入檔案格式錯誤或資料重複。
      break;
    case 'SYSTEM_9999':
      msg = 'Unknown error, check logs.'; // 不明錯誤，Log查詢。
      break;
    default:
      msg = 'error';
  } 
  return msg;
}