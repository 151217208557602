<template>
  <head>
    <title>cuba-Home page</title>
  </head>
  <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
        <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
      </filter>
    </svg>
  </div>
  <router-view v-else></router-view>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
export default {

  name: 'App',
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    // '$route'() {
    //   setTimeout(() => {
    //     this.loading = !this.loading
    //   }, 1000)
    //   this.loading = !this.loading
    // }
  },
  mounted() {
    this.loading = false;
    axios.interceptors.response.use(
      response => { 
        if(response.data.status_code === "SYSTEM_4000"){
          this.$router.push({name:'notFound'})
        }
        return response;
      },
      error => {
        console.log('Rerror', error)
        if(error.request.status === 401){
          console.log('401')
          this.$router.push('/auth/login')
        } else {
           return Promise.reject(error);
        }
        // if (error.code === 'ERR_NETWORK') {
        //   console.log('Aerror', error)
        //   setTimeout(() => this.$router.push('/auth/login'), 1100);
        // }
      }
    );
    
  },
  computed: {
    ...mapState('user', ['accessToken']),
  },
}
</script>
