<template>
  <div class="card map-sidebarh">
    <div class="card-header d-flex justify-content-between align-items-center g-2 flex-wrap border-bottom-0 text-secondary">
      <div class="d-flex align-items-center gap-1">
        <router-link :to="getRoute('deviceList')" class="link-dark">
          <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
        </router-link>
        <p class="fs-5 mb-0">{{ sensorInfo.name }}</p>
        <a href="javascript:;"  @click.prevent="showModal('editDeviceModal')">
            <vue-feather type="edit" size="18" stroke="#08b258" class="ms-2"></vue-feather>
          </a>
      </div>
      <p class="fs-small mb-0">Last Update: {{ sensorInfo.last_update_time }}</p>
    </div>
    <div class="card-body">
      <div v-if="sensorInfo.gateway" class="d-flex g-3 justify-content-between align-items-center gap-1 mb-3">
        <div class="d-flex gap-2 txt-primary align-items-center">
            <vue-feather type="share-2" fill="#14a1b1"></vue-feather>
            <div>
            <p class="fs-sm mb-0">Gateway</p>
            <small class="d-block text-secondary">{{ sensorInfo.gateway.gid }}</small>
            </div>
        </div>
        <router-link :to="getRoute('gateway', sensorInfo.gateway.id)">
            <i class="fa fa-sliders txt-primary fs-5" aria-hidden="true"></i>
        </router-link>
        </div>
      <div class="d-flex justify-content-between align-items-center gap-1 mb-3">
        <div v-if="sensorInfo.battery" class="d-flex align-items-center gap-2" :class="{
          'txt-primary': sensorInfo.battery.status === 'good',
          'text-warning': sensorInfo.battery.status === 'risk',
          'txt-secondary': sensorInfo.battery.status === 'critical'
        }">
            <Battery60Icon :size="18"/>
            <p v-if="sensorInfo.battery" class="mb-0">Est. {{ sensorInfo.battery.value }} Days Left</p>
        </div>
        <div v-if="sensorInfo.connection" class="d-flex align-items-center gap-2" :class="{
          'txt-primary': sensorInfo.connection.status === 'good',
          'text-warning': sensorInfo.connection.status === 'risk',
          'txt-secondary': sensorInfo.connection.status === 'critical'
        }">
            <WifiStrength3Icon :size="18"/>
            <p v-if="sensorInfo.connection" class="mb-0">{{ $capitalizeFirst(sensorInfo.connection.status) }} connection</p>
        </div>
      </div>
      <div class="mb-3">
          <multiselect 
          :options="options.group" 
          :searchable="false" 
          :close-on-select="true" 
          :show-labels="false" 
          track-by="val"
          label="name" 
          v-model="selected.group" 
          placeholder="Group"
          :allow-empty="false" 
          @update:modelValue="selectChange"
          >
          </multiselect>
        </div>
      <div class="d-flex align-items-center gap-2 mb-3">
        <div>
          <multiselect 
            v-model="selected.status"
            :options="options.status" 
            :searchable="false" 
            :close-on-select="true" 
            :show-labels="false" 
            placeholder="Status"
            track-by="val"
          label="name" 
          :allow-empty="false" >
          </multiselect>
        </div>
        <div class="flex-grow-1">
          <input type="text" v-model="sensorInfo.interval" class="form-control" disabled>
        </div>
        <div class="cursor-p" @click.prevent="refresh">
          <vue-feather type="refresh-cw" size="20"></vue-feather>
        </div>
      </div>
      <div v-if="sensorInfo.device_version_list && sensorInfo.device_version_list.length > 0" class="fs-sm text-secondary mb-3">
        <p class="mb-0">Firmware Version: {{ sensorInfo.device_version_list.find(item => item.name === 'gfv').version }}</p>
        <p class="mb-0">Bootloader Firmware Version: {{ sensorInfo.device_version_list.find(item => item.name === 'gbfv').version }}</p>
        <p class="mb-0">Nisus Firmware Version: {{ sensorInfo.device_version_list.find(item => item.name === 'kfv').version }}</p>
        <p class="mb-0">Wi-Fi/BT Firmware Version: {{ sensorInfo.device_version_list.find(item => item.name === 'wfv').version }}</p>
        <p class="mb-0">node Firmware Version: {{ sensorInfo.device_version_list.find(item => item.name === 'nfv').version }}</p>
        <p class="mb-4">node Bootloader Firmware Version: {{ sensorInfo.device_version_list.find(item => item.name === 'nfbv').version }}</p>
        <p class="mb-0">Last successful check for update</p>
        <p class="mb-0">{{ sensorInfo.last_check_update_time }}</p>
      </div>
      <div class="btns-wrap d-flex flex-column justify-content-end gap-2">
         <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2" @click.prevent="showModal('resetModal')">
          <vue-feather type="repeat" size="18" class=""></vue-feather>
          <p class="mb-0">Reset</p>
        </button>
        <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2" @click.prevent="showModal('replaceModal')">
          <vue-feather type="repeat" size="18" class=""></vue-feather>
          <p class="mb-0">Replace</p>
        </button>
        <button type="button" class="btn btn-outline-primary d-flex align-items-center justify-content-center gap-2" @click.prevent="showModal('deleteModal')">
          <vue-feather type="trash-2" size="18" class=""></vue-feather>
          <p class="mb-0">Delete</p>
        </button>
      </div>
      <div class="row g-3">
        <div class="col-12 col-xxxl-6" v-if="sensorInfo.temperature">
          <router-link :to="getRoute('temperature')"
            :class="{
              'shadow shadow-sm border border-light d-flex align-items-center justify-content-between gap-2 p-3': true,
              'text-danger': sensorInfo.temperature.status === 'critical',
              'text-warning': sensorInfo.temperature.status === 'risk',
              'txt-primary': sensorInfo.temperature.status === 'good'
            }">
            <div class="d-flex flex-column align-items-center flex-grow-0">
              <ThermometerIcon size="44" />
              <p class="fs-sm mb-0">{{ sensorInfo.temperature.status }}</p>
            </div>
            <div class="d-flex flex-column align-items-end">
              <h3>{{ sensorInfo.temperature.value }}° F</h3>
              <div class="d-flex justify-content-between gap-2 fw-bold">
                <p v-if="sensorInfo.temperature.value" class="mb-0">{{ Math.round((sensorInfo.temperature.value - 32) * 5 / 9) }}°C</p>
                <p class="mb-0">Temperature</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-xxxl-6" v-if="sensorInfo.humidity">
          <router-link :to="getRoute('humidity')"
            :class="{
              'shadow shadow-sm border border-light d-flex align-items-center justify-content-between gap-2 p-3 hover-o8': true,
              'text-danger': sensorInfo.humidity.status === 'critical',
              'text-warning': sensorInfo.humidity.status === 'risk',
              'txt-primary': sensorInfo.humidity.status === 'good'
            }">
            <div class="d-flex flex-column align-items-center flex-grow-0">
              <WaterPercentIcon size="44"/>
              <p class="fs-sm mb-0">{{ sensorInfo.humidity.status }}</p>
            </div>
            <div class="d-flex flex-column align-items-end justify-content-between fw-bold">
              <h3>{{ sensorInfo.humidity.value }}%</h3>
              <p class="fw-bold mb-0">Relative Humidity</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-xxxl-6" v-if="sensorInfo.moisture">
          <router-link :to="getRoute('moisture')"
            :class="{
              'shadow shadow-sm border border-light d-flex align-items-center justify-content-between gap-2 p-3': true,
              'text-danger': sensorInfo.moisture.status === 'critical',
              'text-warning': sensorInfo.moisture.status === 'risk',
              'txt-primary': sensorInfo.moisture.status === 'good'
            }">
            <div class="d-flex flex-column align-items-center flex-grow-0">
              <HomeOutlineIcon size="44"/>
              <p class="fs-sm mb-0">{{ sensorInfo.moisture.status }}</p>
            </div>
            <div class="d-flex flex-column align-items-end">
              <h3>{{ sensorInfo.moisture.value }}%</h3>
              <p class="fw-bold mb-0">Moisture Level</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-xxxl-6" v-if="sensorInfo.audio">
          <router-link :to="getRoute('file')"
            :class="{
              'shadow shadow-sm border border-light d-flex align-items-center justify-content-between gap-2 p-3': true,
              'text-danger': sensorInfo.audio.status === 'critical',
              'text-warning': sensorInfo.audio.status === 'risk',
              'txt-primary': sensorInfo.audio.status === 'good'
            }">
            <div class="d-flex flex-column align-items-center flex-grow-0">
              <EarHearingIcon size="44"/>
              <p class="fs-sm mb-0">{{ sensorInfo.audio.status }}</p>
            </div>
            <div class="d-flex flex-column align-items-end">
              <h3>{{ sensorInfo.audio.value }}</h3>
              <p class="fw-bold mb-0">Files</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <resetModal v-if="shown.resetModal" ref="resetModal" :type="'sensor'"
    @mqttReset="(resetType) => mqttReset(resetType)"/>
  <replaceModal v-if="shown.replaceModal" ref="replaceModal" :type="'sensor'"
   @mqttReplace="mqttUpdate('replace')"/>
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" :type="'sensor'" :id="$route.params.sensorId"
   @update="getSensorInfo" @hide="hideModal('deleteModal')" @mqttDelete="mqttUpdate('delete')"/>
  <editDeviceModal v-if="shown.editDeviceModal" ref="editDeviceModal" :type="'sensor'" :data="sensorInfo"
    @update="getSensorInfo" @hide="hideModal('editDeviceModal')"/>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import resetModal from '@/components-gc/modal/reset.vue'
import replaceModal from '@/components-gc/modal/replace.vue'
import deleteModal from '@/components-gc/modal/delete.vue'
import editDeviceModal from '@/components-gc/modal/editDevice.vue'
import mqtt from "mqtt";
import { checkStatusCode } from '@/methods-gc/statusCode';
import _ from 'lodash';

export default {
  data() {
    return {
      sensorInfo: {},
      options: {
        status: [
          { val: 0, name: 'Disable' },
          { val: 1, name: 'Enable' }
        ],
        condition: [
          { val: 'auto', name: 'Interval detection: auto', seconds: 0 },
          { val: 'halfDay', name: 'Interval detection: halfDay', seconds: 43200 },
          { val: 'oneDay', name: 'Interval detection: oneDay', seconds: 86400 },
          { val: 'oneHalfDay', name: 'Interval detection: oneHalfDay', seconds: 129600 },
          { val: 'twoDays', name: 'Interval detection: twoDays', seconds: 172800 },
          { val: 'threeDays', name: 'Interval detection: threeDays', seconds: 259200 },
          { val: 'fourDays', name: 'Interval detection: fourDays', seconds: 345600 },
          { val: 'fiveDays', name: 'Interval detection: fiveDays', seconds: 432000 },
          { val: 'sixDays', name: 'Interval detection: sixDays', seconds: 518400 },
          { val: 'sevenDays', name: 'Interval detection: sevenDays', seconds: 604800 },
        ],
        group: [
          { val: 1, name: 'Group 1' },
          { val: 2, name: 'Group 2' },
          { val: 3, name: 'Group 3' },
          { val: 4, name: 'Group 4' },
          { val: 5, name: 'Group 5' },
          { val: 6, name: 'Group 6' },
          { val: 7, name: 'Group 7' },
          { val: 8, name: 'Group 8' },
          { val: 9, name: 'Group 9' },
          { val: 10, name: 'Group 10' },
        ],
      },
      selected: {
        status: null,
        condition: null,
        group: null,
      },
      mqttConfig: {
        broker: process.env.VUE_APP_MQTT_BROKER,
        clientId: this.$route.params.sensorId,
        username: process.env.VUE_APP_MQTT_USERNAME,
        password: process.env.VUE_APP_MQTT_PASSWORD,
        gid: null,
      },
      retryWait: 1000,
      maxRetries: 3,
      isSettingUpdate: false,
      isSettingReset: false,
      mqttType: null,
      resetType: null,
      loadingId: null,
      modals: {},
      shown: {
        resetModal: false,
        replaceModal: false,
        deleteModal: false,
        editDeviceModal: false,
      },
    }
  },
  components: {
    resetModal, replaceModal, deleteModal, editDeviceModal
  },
  mounted() {
    this.getSensorInfo();
  },
  methods: {
    getSensorInfo() {
      const api = `${process.env.VUE_APP_PATH}/house/device/sensor_info`;
      this.axios.post(api, {
        house_id: this.$route.params.houseId,
        sensor_id: this.$route.params.sensorId
      })
      .then((res) => {
        const msg = checkStatusCode(res.data.status_code);
        if (res.data.status_code === 'SYSTEM_1000') {
          this.sensorInfo = res.data.sensor;
          this.selected.group = this.options.group.find(item => item.val === this.sensorInfo.group_id);
          this.selected.condition = this.options.condition.find(item => item.val === this.sensorInfo.interval);
          this.selected.status = this.options.status.find(item => item.val === this.sensorInfo.enable);
          this.mqttConfig.gid = this.sensorInfo.gateway.gid; // update gid
        } else if(res.data.status_code === 'SYSTEM_0007') {
          this.$toastWarning(`${msg} You will be redirected to the list.`);
          setTimeout(() => {
            this.$router.push(this.getRoute('deviceList'));
          }, 4000);
        } else {
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    refresh() {
      if (this.selected.status && this.selected.status.val !== this.sensorInfo.enable) {
        this.switchSensor();
      }
    },
    switchSensor() {
      const api = `${process.env.VUE_APP_PATH}/house/device/sensor/switch`;
      this.axios.post(api, {
        sensor_id: this.$route.params.sensorId,
        enable: this.selected.status.val
      })
      .then((res) => {
        const msg = checkStatusCode(res.data.status_code);
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Switch successfully.');
          this.getSensorInfo();
        } else {
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    mqttUpdate: _.debounce(async function(type) {
      console.log('isSettingUpdate', this.isSettingUpdate)
      if (this.isSettingUpdate) return;
      this.isSettingUpdate = true;
      this.mqttType = type;

      const loadingId = this.$toastLoading('Processing, please wait...');
      const topicAP = `nk/tm/${this.mqttConfig.gid}/toAP`;
      const topicGW = `nk/tm/${this.mqttConfig.gid}/toGW`;
      const { broker, clientId, username, password } = this.mqttConfig;
      const client = mqtt.connect(broker, { clientId, username, password });
      let receivedOk = false;
      let retryCount = 0;
      const self = this;

      client.on("connect", update);

      async function update() {
        if (retryCount >= 3) {
          console.log('Max retries reached, stopping resends.');
          self.$toastUpdate(loadingId, 'Failed to update.', {
            type: 'error',
          });
          self.isSettingUpdate = false;
          if (client.connected) client.end();
          return;
        }

        console.log('update Connected to broker', 'retryCount', retryCount, receivedOk)
        client.subscribe(topicAP, async (error) => {
          if (error) {
            console.log("Subscribe error:", error);
            self.$toastUpdate(loadingId, 'Failed to update.', {
              type: 'error',
            });
            self.isSettingUpdate = false;
          } else {
            console.log("Subscribed successfully");
            await self.publishMsg('update', client, topicGW, { ls: true }, loadingId);
            receivedOk = await self.receiveMsg('update', client, topicAP, loadingId);

            if (!receivedOk && retryCount < self.maxRetries) {
              console.log('retry', retryCount)
              retryCount++; 
              setTimeout(() => {
                update();
              }, self.retryWait);
            } else if (!receivedOk) {
              console.log('no response', retryCount);
              self.$toastUpdate(loadingId, 'Failed to update.', {
                type: 'error',
              });
              self.isSettingUpdate = false;
            }
          }
        });
      }
    }, 300, { leading: true, trailing: false }),

    mqttReset: _.debounce(async function(resetType) {
      if (this.isSettingReset) return;
      this.isSettingReset = true;

      this.resetType = resetType === 'sensor' ? this.sensorInfo.nid : resetType;
      const loadingId = this.$toastLoading('Processing, please wait...');

      const topicAP = `nk/tm/${this.mqttConfig.gid}/toAP`;
      const topicGW = `nk/tm/${this.mqttConfig.gid}/toGW`;
      const { broker, clientId, username, password } = this.mqttConfig;
      const client = mqtt.connect(broker, { clientId, username, password });
      let receivedOk = false;
      let retryCount = 0;
      const self = this;

      client.on("connect", reset);

      async function reset() {
        if (retryCount >= 3) {
          console.log('Max retries reached, stopping resends.');
          self.$toastUpdate(loadingId, 'Failed to reset.', {
            type: 'error',
          });
          self.isSettingReset = false;
          if (client.connected) client.end();
          return;
        }

        console.log('reset Connected to broker', 'retryCount', retryCount, receivedOk)
        client.subscribe(topicAP, async (error) => {
          if (error) {
            console.log("Subscribe error:", error);
            self.$toastUpdate(loadingId, 'Failed to reset.', {
              type: 'error',
            });
            self.isSettingReset = false;
          } else {
            console.log("Subscribed successfully");
            await self.publishMsg('reset', client, topicGW, { nid: self.resetType, rs: true }, loadingId);
            receivedOk = await self.receiveMsg('reset', client, topicAP, loadingId);

            if (!receivedOk && retryCount < self.maxRetries) {
              console.log('retry', retryCount)
              retryCount++; 
              setTimeout(() => {
                reset();
              }, self.retryWait);
            } else if (!receivedOk) {
              console.log('no response', retryCount);
              self.$toastUpdate(loadingId, 'Failed to reset.', {
                type: 'error',
              });
              self.isSettingReset = false;
            }
          }
        });
      }
    }, 300, { leading: true, trailing: false }),

    publishMsg(type, client, topicGW, payload, loadingId) {
      return new Promise((resolve, reject) => {
        client.publish(topicGW, JSON.stringify(payload), (error) => {
          if (error) {
            console.log("Publish error:", error);
            this.$toastUpdate(loadingId, `Failed to ${type}.`, {
              type: 'error',
            });
            reject(error);
          } else {
            console.log("Message published successfully");
            resolve();
          }
        });
      });
    },

    receiveMsg(type, client, topicAP, loadingId) {
      let receivedOk = false;
      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          if (!receivedOk) {
            console.log('Timeout reached, no response received.');
            resolve(false);
          }
        }, 1000);

        client.on("message", (topic, message) => {
          const msg = JSON.parse(message.toString());
          console.log('Received message', "msg", msg);
          if (topic === topicAP) {
            switch(type) {
              case 'reset':
                if (msg.s_rs === 'ok') {
                  this.hideModal('resetModal');
                  this.$toastUpdate(loadingId, 'Reset successfully!', {
                    type: 'success',
                  });
                  receivedOk = true;
                  this.isSettingReset = false;
                }
                break;
              case 'update':
                if (msg.s_ls === 'ok') {
                  if(this.mqttType === 'delete') {
                    this.hideModal('deleteModal');
                    this.$toastUpdate(loadingId, 'Deleted successfully!', {
                      type: 'success',
                    });
                  } else if(this.mqttType === 'replace') {
                    this.hideModal('replaceModal');
                    this.$toastUpdate(loadingId, 'Replaced successfully!', {
                      type: 'success',
                    });
                  }
                  this.getSensorInfo();
                  receivedOk = true;
                  this.isSettingUpdate = false;
                }
                break;
              default:
                console.log('Unknown message type');
            }

            if (receivedOk) {
              clearTimeout(timeout);
              if (client.connected) client.end();
              this[`isSetting${this.$capitalizeFirst(type)}`] = false;
              resolve(true);
            } else {
              console.log('No response received, resending message...');
              resolve(false);
            }
          }
        });
      });
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getRoute(type, id = null) {
      const routeMap = {
        userSensor: {
          deviceList: 'userDeviceList',
          gateway: 'userGateway',
          temperature: 'userTemperature',
          humidity: 'userHumidity',
          moisture: 'userMoistureLevel',
          file: 'userFiles'
        },
        staffSensor: {
          deviceList: 'staffDeviceList',
          gateway: 'staffGateway',
          temperature: 'staffTemperature',
          humidity: 'staffHumidity',
          moisture: 'staffMoistureLevel',
          file: 'staffFiles'
        },
        houseSensor: {
          deviceList: 'houseDeviceList',
          gateway: 'houseGateway',
          temperature: 'houseTemperature',
          humidity: 'houseHumidity',
          moisture: 'houseMoistureLevel',
          file: 'houseFiles'
        }
      };

      const routeName = this.$route.name;
      if (routeMap[routeName] && routeMap[routeName][type]) {
        return { name: routeMap[routeName][type], params: { gatewayId: id } };
      }
      console.log(routeName);
    },
    selectChange(item) {
      if(item){
        const api = `${process.env.VUE_APP_PATH}/house/device/sensor/group`;
        this.axios.post(api, {
          sensor_id: this.$route.params.sensorId,
          house_id: this.$route.params.houseId, 
          group_id: item.val,
        })
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$toastSuccess('Updated successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
            this.getGateInfo();
          }
        }).catch(error => {
            console.log('Error:', error);
          });
      }
    } 
  }
}
</script>

<style scoped>
.vue-feather.vue-feather--refresh-cw {
  color: var(--theme-deafult);
}
@media (min-width: 1500px) {
  .btns-wrap {
    flex-direction: row!important;
  }
}
</style>