<template>
  <div>

    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <!-- <div>
                <a class="logo">
                  <img class="img-fluid for-light" src="../assets/images/logo/logo.png" alt="looginpage" />
                  <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="looginpage" />
                </a>
              </div> -->
              <div class="login-main">
                <form class="theme-form">
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" required="" placeholder="email"
                      v-model="user.email.value">
                    <span class="validate-error" v-if="!user.email.value || !validEmail(user.email.value)">{{
                      user.email.errormsg }}</span>

                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input class="form-control" :type="passwordType" name="login[password]" required=""
                        placeholder="password" v-model="user.password.value">
                      <span class="validate-error" v-if="user.password.value.length < 7">{{ user.password.errormsg
                      }}</span>

                      <div class="show-hide" @click="passwordVisibility">
                        <span class="show"></span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" v-model="rememberMe">
                      <label class="text-muted" for="checkbox1">Remember password</label>
                    </div>
                    <!-- <router-link class="link" to="/authentication/forgetpassword"> Forgot password?</router-link> -->
                    <div class="text-end mt-3">
                      <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="login">Sign
                        in</button>

                    </div>
                  </div>
                  <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                  <div class="social mt-4">
                    <div class="btn-showcase"><a class="btn btn-light" href="https://www.linkedin.com/login"
                        target="_blank"><i class="txt-linkedin" data-feather="linkedin"></i> LinkedIn </a><a
                        class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank"><i
                          class="txt-twitter" data-feather="twitter"></i>twitter</a><a class="btn btn-light"
                        href="https://www.facebook.com/" target="_blank"><i class="txt-fb"
                          data-feather="facebook"></i>facebook</a></div>
                  </div>
                  <p class="mt-4 mb-0 text-center">Don't have account?<a class="ms-2" href="sign-up.html">Create
                      Account</a>
                  </p> -->
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { checkStatusCode } from '../methods-gc/statusCode';

export default {
  name: 'login',
  mounted() {
    this.loadRememberedCredentials();
  },
  data() {
    return {
      result: { email: '', password: '' },
      user: {
        email: {
          value: '',
          errormsg: ''
        },
        password: {
          value: '',
          errormsg: ''
        },
      },
      passwordType: 'password',
      rememberMe: false
    };
  },
  methods: {
    ...mapMutations('user', ['setToken']),
    login() {
      this.validateLogin();

      const api = `${process.env.VUE_APP_PATH}/member/login`;
      this.axios.post(api, {
        email: this.user.email.value,
        password: this.user.password.value
      }, {
        headers: {
          'apiToken': process.env.VUE_APP_API_TOKEN
        }
      })
      .then((res) => {
          if(res.data.status_code === 'SYSTEM_1000') {
            this.setToken(res.data.access_token);
            this.$router.push('/');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => console.log(error))
    },
    validateLogin() {
      if (!this.user.email.value || !this.user.password.value) {
        this.$toastError('empty not allowed');
        return
      } else if (!this.validEmail(this.user.email.value)) {
        this.$toastError('Valid email required');
        return
      }

      if (this.rememberMe) {
        localStorage.setItem('rememberMe', true);
        localStorage.setItem('email', this.user.email.value);
        localStorage.setItem('password', this.user.password.value);
      } else {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    passwordVisibility(e) {
      const hidePassword = this.passwordType === 'password';
      const span = e.target;
      if(hidePassword){
        span.classList.remove('show');
        span.classList.add('hide');
      } else {
        span.classList.remove('hide');
        span.classList.add('show');
      }
      this.passwordType = hidePassword ? 'text' : 'password';
    },
    loadRememberedCredentials() {
      if (localStorage.getItem('rememberMe')) {
        this.rememberMe = true;
        this.user.email.value = localStorage.getItem('email');
        this.user.password.value = localStorage.getItem('password');
      }
    },
    
  },
};
</script>