<template>
  <Breadcrumbs title="House Detail" main="House List"/>
  <div class="container-fluid">
    <div class="d-flex justify-content-between gap-2 mb-3">
      <div>
        <div class="d-flex align-items-center gap-1 mb-2">
          <router-link :to="{ name: 'houseListIndex' }" class="link-dark">
            <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
          </router-link>
          <h5 class="mb-0">{{ houseData.name }}</h5>
        </div>
        <p class="mb-0 text-secondary">{{ houseData.address }}</p>
      </div>
      <button type="button" class="btn btn-third align-self-center" @click.prevent="getQRcode">Download QR</button>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5 class="f-w-400 text-secondary mb-4">#{{ houseData.hid }}</h5>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="text-secondary fs-6 mb-0">Home Information</p>
              <div class="fw-semibold">
                <vue-feather type="edit" size="16" class="me-2 text-primary"></vue-feather>
                <a @click.prevent="showModal('editHouse')" href="javascript:;" class="text-primary">Edit</a>
              </div>
            </div>
            <div>
              <div class="d-flex gap-2 mb-3">
                <img :src="houseData.image || 'https://fakeimg.pl/50x50/?text=50x50'" class="img-50" alt="houseData.name">
                <div>
                  <h6>{{ houseData.name }}</h6>
                  <p class="mb-0 text-secondary fs-sm">{{ houseData.postcode }} {{ houseData.address }} {{ houseData.city }} {{ houseData.state }} {{ houseData.country }}</p>
                </div>
              </div>
              <div class="d-flex gap-3 flex-wrap justify-content-between">
                <div>
                  <h6 class="mb-0">Created at</h6>
                  <p class="mb-0 text-secondary">{{ houseData.create_at }}</p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="text-secondary fs-6 mb-0">Owner Information</p>
              <div v-if="houseData.owner" class="fw-semibold">
                <vue-feather type="edit" size="16" class="me-2 text-primary"></vue-feather>
                <a @click.prevent="showModal('editOwner')" href="javascript:;" class="text-primary">Edit</a>
              </div>
            </div>
            <div v-if="houseData.owner" class="row">
              <div class="col-md-7 mb-3">
                <div class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="houseData.owner.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ houseData.owner.first_name }} {{ houseData.owner.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ houseData.owner.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-3">
                <h6>Created at</h6>
                <p class="mb-0 text-secondary">{{ houseData.owner.created_at }}</p>
              </div>
              <div class="col-md-7">
                <div class="d-flex align-items-start gap-2">
                  <div class="img-40"></div>
                  <div>
                    <h6>Mobile</h6>
                    <p class="mb-0 text-secondary">{{ houseData.owner.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <h6>Local Phone</h6>
                <p class="mb-0 text-secondary">{{ houseData.owner.local_phone }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <p class="text-secondary mb-0 fs-5">Assign Information</p>
              <div v-if="houseData.assigned" class="fw-semibold">
                <vue-feather type="edit" size="16" class="me-2 text-primary"></vue-feather>
                <a v-if="houseData.assigned" @click.prevent="showModal('editAssign')" href="javascript:;" class="text-primary">Edit</a>
              </div>
            </div>
            <div v-if="houseData.assigned" class="row">
              <div class="col-md-7 mb-3">
                <div class="d-flex align-items-center gap-2">
                  <img class="img-40 rounded-circle" :src="houseData.assigned.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6 class="mb-0">{{ houseData.assigned.first_name }} {{ houseData.assigned.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ houseData.assigned.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-3">
                <h6>Assign At</h6>
                <p class="mb-0 text-secondary">{{ houseData.assigned.assigned_at }}</p>
              </div>
              <div class="col-md-7">
                <div class="d-flex align-items-center gap-2">
                  <div class="img-40"></div>
                  <div>
                    <h6>Mobile</h6>
                    <p class="mb-0 text-secondary">{{ houseData.assigned.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <h6>Local Phone</h6>
                <p class="mb-0 text-secondary">{{ houseData.local_phone }}</p>
              </div>
            </div>
            <div v-else class="text-center">
              <button type="button" class="btn btn-primary" @click.prevent="showModal('editAssign')">Assign Staff</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <GoogleMap v-if="showMap"
          :api-key="apiKey"
          style="width: 100%; height: 1000px"
          :center="center"
          :zoom="15"
        >
          <Marker :options="{ position: center }" />
        </GoogleMap>
        <div v-else>
          <p class="text-center">No map found for this address</p>
        </div>
      </div>
      <div class="col-md-4">
        <router-view></router-view>
      </div>
    </div>
  </div>

  <editHouse v-if="shown.editHouse" ref="editHouse" :houseData="houseData" 
  @hide="hideModal('editHouse')" @update="getHouseData"/>
  <editOwner v-if="shown.editOwner" ref="editOwner" :ownerData="houseData.owner" @hide="hideModal('editOwner')" @update="getHouseData"/>
  <editAssign v-if="shown.editAssign" ref="editAssign" :staffData="houseData.assigned" @hide="hideModal('editAssign')" @update="getHouseData"/>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleMap, Marker } from 'vue3-google-map';
import Modal from "bootstrap/js/dist/modal";
import editHouse from '@/components-gc/modal/houseList/editHouse.vue';
import editOwner from '@/components-gc/modal/houseList/editOwner.vue';
import editAssign from '@/components-gc/modal/houseList/editAssign.vue';
import { checkStatusCode } from '@/methods-gc/statusCode'; 

export default {
  data() {
    return {
      showMap: false,
      center: { lat: 0, lng: 0 },
      houseData: {}, 
      modals: {},
      shown: {
        editHouse: false,
        editOwner: false,
        editAssign: false,
      },
      qrcodeImg: '',
      verifyCode: ''
    }
  },
     mounted() {
      this.getHouseData();
    },
  components: {
    GoogleMap, Marker, editHouse, editOwner, editAssign
  },
  methods: {
    getHouseData() {
      const api = `${process.env.VUE_APP_PATH}/house/detail`;
      this.axios.post(api, { house_id: this.$route.params.houseId })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.houseData = res.data.house;
          this.initMap();
        } else if(res.data.status_code === 'SYSTEM_0004') {
          this.$router.push({ name: 'houseListIndex' });
        } 
        else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    initMap() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          language: "en" 
        });
      
      loader.load().then(async () => {
        const { Geocoder } = await google.maps.importLibrary("geocoding")
        
        /* 將地址轉為經緯度 */
        const geocoder = new Geocoder();
        geocoder.geocode({ 'address': this.houseData.address }, (results, status) => {
          if (status === 'OK') {
            this.center.lat = results[0].geometry.location.lat(); 
            this.center.lng = results[0].geometry.location.lng(); 
            this.showMap = true;
          } else {
            console.error('查無座標：', status);
          }
        });
      });
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getQRcode() {
      const api = `${process.env.VUE_APP_PATH}/house/download_qrcode`;
      this.axios.post(api, {
        house_id : this.$route.params.houseId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.qrcodeImg = res.data.image;
          this.verifyCode = res.data.verify_code;
          this.downloadQR();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    downloadQR() {
      if (this.qrcodeImg && this.verifyCode) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = `data:image/png;base64,${this.qrcodeImg}`;
        
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height + 50; // 增加高度以顯示 verifyCode
          
          ctx.drawImage(img, 0, 0);
          ctx.font = '20px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(this.verifyCode, canvas.width / 2, img.height + 30); // 在 QR Code 下方顯示 verifyCode
          
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = 'qrcode_with_verify_code.png';
          document.body.appendChild(link); // 將 <a> 元素添加到 DOM 中
          link.click(); // 觸發點擊事件
          document.body.removeChild(link); // 點擊後移除 <a> 元素
        };
      } else {
        console.error('QR Code can not be downloaded');
      }
    },
  },
}
</script>

<style scoped>
</style>