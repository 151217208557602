<template>
  <Breadcrumbs :title="`${houseDevice.firstName} ${houseDevice.lastName}`" main="Staff Management" :title2="houseDevice.houseName"/>
  <div class="container-fluid">
    <div class="d-flex justify-content-between gap-2">
      <div>
        <div class="d-flex align-items-center gap-1 mb-2">
          <router-link :to="{ name: 'staffCaseDetail' }" class="link-dark">
            <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
          </router-link>
          <h5 class="mb-0">{{ houseDevice.houseName }}</h5>
        </div>
        <p class="mb-0 text-secondary">{{ houseDevice.houseAddress }}</p>
      </div>
      <button type="button" class="btn btn-third align-self-center" @click.prevent="getQRcode">Download QR</button>
    </div>
    <div class="row">
      <div class="col-md-8">
        <GoogleMap v-if="showMap"
          :api-key="apiKey"
          style="width: 100%; height: 1000px"
          :center="center"
          :zoom="15"
          >
            <Marker :options="{ position: center }" />
        </GoogleMap>
        <div v-else>
          <p class="text-center">No map found for this address</p>
        </div>
      </div>
      <div class="col-md-4">
          <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleMap, Marker } from 'vue3-google-map'
import { mapState } from 'vuex';
import { checkStatusCode } from '@/methods-gc/statusCode';



export default {
  data() {
    return {
      showMap: false,
      center: { lat: 0, lng: 0 },
      qrcodeImg: '',
      verifyCode: ''
    }
  },
  mounted() {
    this.initMap();
  },
  components: {
    GoogleMap, Marker
  },
  computed: {
        ...mapState('user', ['houseDevice']),
    },
  methods: {
    initMap() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          language: "en" 
        });
      
      loader.load().then(async () => {
        const { Geocoder } = await google.maps.importLibrary("geocoding")
        
        /* 將地址轉為經緯度 */
        const geocoder = new Geocoder();
        geocoder.geocode({ 'address': this.houseDevice.houseAddress }, (results, status) => {
          if (status === 'OK') {
            this.center.lat = results[0].geometry.location.lat(); 
            this.center.lng = results[0].geometry.location.lng(); 
            this.showMap = true;
          } else {
            console.error('查無座標：', status);
          }
        });
      });
    },
    getQRcode() {
      const api = `${process.env.VUE_APP_PATH}/house/download_qrcode`;
      this.axios.post(api, {
        house_id : this.$route.params.houseId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.qrcodeImg = res.data.image;
          this.verifyCode = res.data.verify_code;
          this.downloadQR();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    downloadQR() {
      if (this.qrcodeImg && this.verifyCode) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = `data:image/png;base64,${this.qrcodeImg}`;
        
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height + 50; // 增加高度以顯示 verifyCode
          
          ctx.drawImage(img, 0, 0);
          ctx.font = '20px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(this.verifyCode, canvas.width / 2, img.height + 30); // 在 QR Code 下方顯示 verifyCode
          
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = 'qrcode_with_verify_code.png';
          document.body.appendChild(link); // 將 <a> 元素添加到 DOM 中
          link.click(); // 觸發點擊事件
          document.body.removeChild(link); // 點擊後移除 <a> 元素
        };
      } else {
        console.error('QR Code can not be downloaded');
      }
    },
  },
}
</script>