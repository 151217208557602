<template>
  <router-link to="/">
    <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo.svg"
      alt=""
      width="60"
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/logo_dark.svg"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
