<template>
  <Breadcrumbs :title="`${staffData.first_name} ${staffData.last_name}`" main="Staff Management"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-header">
            <div class="d-flex gap-2  justify-content-between align-items-start">
              <h5 class="f-w-400 text-secondary">{{ $route.params.staffId }}</h5>
              <div class="d-flex gap-2 align-items-center">
                <label :class="[ 'form-check-label', 'mb-0','fw-semibold','fs-6', staffData.status ? 'txt-primary' : 'text-secondary']">
                  {{ staffData.status ? 'Enabled' : 'Disabled' }}
                </label>                
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" 
                         :true-value="1" :false-value="0"
                         v-model="staffData.status" 
                         @change="switchUserStatus(staffData)">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
                <div class="col-md-7 mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <img class="img-40 rounded-circle" :src="staffData.image ? staffData.image : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                    <div>
                      <h6 class="mb-0">{{ staffData.first_name }} {{ staffData.last_name }}</h6>
                      <p class="mb-0 text-secondary">{{ staffData.email }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 mb-3">
                  <h6>Created at</h6>
                  <p class="mb-0 text-secondary">{{ staffData.created_at }}</p>
                </div>
                <div class="col-md-7 mb-5">
                  <div class="d-flex align-items-start gap-2">
                    <div class="img-40"></div> 
                    <div>
                      <h6 >Mobile</h6>
                      <p class="mb-0 text-secondary">{{ staffData.phone }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 mb-5"> 
                  <h6>Local Phone</h6>
                  <p class="mb-0 text-secondary">{{ staffData.local_phone }}</p>
                </div>
             
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <a href="javascript:;" class="fs-6 fw-semibold" @click="showModal('resetPassword')">Reset Password</a>
                </div>
                <div class="fw-semibold">
                  <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                  <a href="javascript:;" class="fs-6 text-primary" @click="showModal('editProfile')">Edit Profile</a>
                </div>
              </div>

          </div>
        </div>
        <div class="card">
          <div class="card-header border-bottom-0">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h5 class="f-w-400 text-secondary">Service Area</h5>
              <div v-if="serviceAreas.length > 0" class="fw-semibold">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="fs-6 text-primary" @click="showModal('editServiceArea')">Edit</a>
              </div>
            </div>
            <div>
              <div v-for="area in serviceAreas" :key="area.id" class="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <p class="mb-0">Service Area</p>
                  <p class="mb-0 fs-sm text-secondary">{{ area.city }} {{ area.state }} , {{ area.country }}</p>
                </div>
                <p v-if="area.default" class="mb-0 fs-sm badge align-self-center bg-primary">Default</p>
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn btn-primary" @click="showModal('addServiceArea')">+ Add Service Area</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="card">
          <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
            <li class="nav-item">
              <a href="javascript:;" class="nav-link" :class="[currentTab === 'assigned' ? 'text-primary active' : '']" @click.prevent="changeTab('assigned')">
                Assigned
              </a>
            </li>
            <li class="nav-item">
              <a href="javascript:;" class="nav-link" :class="[currentTab === 'pending' ? 'text-warning active' : '']" @click.prevent="changeTab('pending')">
                Pending
              </a>
            </li>
            <li class="nav-item">
              <a href="javascript:;" class="nav-link" :class="[currentTab === 'done' ? 'txt-primary active' : '']" @click.prevent="changeTab('done')">
                Done
              </a>
            </li>
          </ul>

          <div v-if="reportList.length > 0" class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Case ID</th>
                  <th scope="col">House ID</th>
                  <th scope="col" @click="sortList('assigned_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Assigned at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('assigned_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('assigned_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('report_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Report at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('report_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('report_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">{{ this.currentTab === 'done' ?  'Report' : 'Action' }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in reportList" :key="item.id">
                  <td>#HS{{ item.id }}</td>
                  <td>
                    <h6 class="text-dark">{{ item.house_name }}</h6>
                    <p class="mb-0">{{ item.house_address }}</p>
                  </td>
                  <td>Jan 5, 2024 12:46 AM</td>
                  <td>
                    <p class="mb-0 fs-sm" :class="{
                        'txt-primary': item.report_device.status === 'good',
                        'text-warning': item.report_device.status === 'risk',
                        'txt-secondary': item.report_device.status === 'critical'
                      }">
                      {{ item.report_device.count }} Devices 
                       {{ item.report_device.status === 'good' ? 'All Good' : 
                         item.report_device.status === 'risk' ? 'At Risk' : 
                         item.report_device.status === 'critical' ? 'Critical' : '' }}
                    </p>
                    <p class="mb-0 fs-sm">{{ item.report_at }}</p>
                  </td>
                 <td>
                  <div v-if="currentTab === 'done'">
                    <router-link :to="{ name: 'staffCaseDetail', params: { caseId: item.id } }" class="badge bg-light align-self-start" :class="{
                      'txt-primary': item.report_device.status === 'good',
                      'text-warning': item.report_device.status === 'risk',
                      'txt-secondary': item.report_device.status === 'critical',
                      'text-dark': item.report_device.status === ''
                    }">Detail</router-link>
                  </div>
                  <div v-else>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="showModal('assignHomeService', item)">Assign</button>
                  </div>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center">
            <p>No data</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <resetPassword v-if="shown.resetPassword" ref="resetPassword" 
  :userId="$route.params.staffId" @hide="hideModal('resetPassword')"/>
  <editProfile v-if="shown.editProfile" ref="editProfile" 
  :staffData="staffData" @hide="hideModal('editProfile')" @getStaffData="getStaffData"/>
  <assignHomeService v-if="shown.assignHomeService" ref="assignHomeService" 
  :staffData="selectedStaff" :reportId="reportId" @hide="hideModal('assignHomeService')" @update="getReportList"/>
  <addServiceArea v-if="shown.addServiceArea" ref="addServiceArea" 
  @hide="hideModal('addServiceArea')" @getServiceAreas="getServiceAreas"/>
  <editServiceArea v-if="shown.editServiceArea" ref="editServiceArea" 
  :serviceAreas="serviceAreas" @hide="hideModal('editServiceArea')" @getServiceAreas="getServiceAreas"/>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import resetPassword from '@/components-gc/modal/member/resetPassword.vue'
import editProfile from '@/components-gc/modal/staffManagement/editProfile.vue'
import assignHomeService from '@/components-gc/modal/staffManagement/assignHomeService.vue'
import addServiceArea from '@/components-gc/modal/staffManagement/addServiceArea.vue'
import editServiceArea from '@/components-gc/modal/staffManagement/editServiceArea.vue'
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      staffData: {},
      reportList: [],
      modals: {},
      // currentTab: 'assigned',
      shown: {
        resetPassword: false,
        editProfile: false,
        assignHomeService: false,
        addServiceArea: false,
        editServiceArea: false,
      },
      serviceAreas: [], // 新增 serviceAreas 資料
      listRequest: {
        order_by: '',
        order: '',
      },
      selectedStaff: null,
      reportId: null,

    }
  },
  computed: {
    currentTab() {
      return this.$route.query.status;
    }
  },
  components: { 
    resetPassword,
    editProfile,
    assignHomeService,
    addServiceArea,
    editServiceArea,
  },
  mounted() {
    this.getStaffData();
    this.getServiceAreas();
    this.getReportList(); // 增 getReportList 方法
  },
  watch: {
    currentTab(newTab) {
      this.getReportList(); // 當 currentTab 變動時重新取得報告列表
    }
  },
  methods: {
    getStaffData() {
      const api = `${process.env.VUE_APP_PATH}/staff/detail`;
      this.axios.post(api, {
        user_id: this.$route.params.staffId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.staffData = res.data.user;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          console.log(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    showModal(name, data = null) {
      if (name === 'assignHomeService') {
        this.selectedStaff = data.staff;
        this.reportId = data.id;
      }
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      if (this.modals[name]) {
        this.modals[name].hide();
      }
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    switchUserStatus(staffData) {
      const api = `${process.env.VUE_APP_PATH}/staff/switch`;
      this.axios.post(api, {
        user_id: this.$route.params.staffId,
        enable: staffData.status === 1
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Status updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
          staffData.status = staffData.status === 1 ? 0 : 1;
        }
      }).catch(error => {
        console.log('Error:', error);
        this.$toastError('Status update failed');
        staffData.status = staffData.status === 1 ? 0 : 1;
      });
    },
    getServiceAreas() {
      console.log('getServiceArea');
      const api = `${process.env.VUE_APP_PATH}/staff/service_area_list`;
      this.axios.post(api, {
        user_id: this.$route.params.staffId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.serviceAreas = res.data.service_area;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          console.log(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
      console.log('end')
    },
    getReportList() {
      const api = `${process.env.VUE_APP_PATH}/staff/reports`;
      this.axios.post(api, {
        user_id: this.$route.params.staffId,
        type: this.currentTab,
        order_by: this.listRequest.order_by,
        order: this.listRequest.order
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.reportList = res.data.reports;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          console.log(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getReportList(); // 排序時重新取得報告列表
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
  },
}
</script>