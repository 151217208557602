<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form ref="form" @submit.prevent="handleDelete">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <vue-feather type="alert-triangle" size="42" class="text-danger mb-3"></vue-feather>
            <h5>Are you sure you want to delete the {{ type }}?</h5>
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-light" @click.prevent="$emit('hide')">Cancel</button>
              <button type="submit" class="btn btn-danger">Confirm</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      
    }
  },
  props: ['type', 'id'],
  emits: ['update', 'hide', 'mqttDelete'],
  mounted() {
  },
  methods: {
    handleDelete() {
      const api = `${process.env.VUE_APP_PATH}/house/device/${this.type}/remove`;
      const payload = {
        [this.type === 'gateway' ? 'gateway_id' : 'sensor_id']: this.id
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          if(this.type === 'gateway') {
            this.$emit('hide');
            this.$toastSuccess('Deleted successfully');
            this.$emit('update');
          } else if(this.type === 'sensor') {
            this.$emit('mqttDelete');
          }
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  
}
</script>